/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, Select, Modal, Row, Col, DatePicker, Checkbox, message } from 'antd';
import { useLocation } from 'react-router-dom';
import { isNull, isUndefined } from 'underscore';
import LocationSearchInput from '../LocationSearchInput';
import '../../../pages/Horoscope/horoscope.scss';
import './signupForm.scss';
import useUser from '../../../core/user/useUser';
import * as userActions from '../../../core/user/userActions';
import { getTranslation } from '../../../core/utils/helpers';
import useLanguage from '../../../core/language/useLanguage';

const SignupForm = () => {
    const { language } = useLanguage();

    const location = useLocation();

    // Get the URL search params from the location object
    const searchParams = new URLSearchParams(location.search);
    // Extract the value of the 'redirect' parameter
    const redirectParam = searchParams.get('redirect');

    const dispatch = useDispatch();

    const form = Form.useForm();

    const [forgetPasswordModalVisible, setForgetPasswordModalVisible] = useState(false);
    const [placeOfBirth, setPlaceOfBirth] = useState('');

    const { user } = useUser();

    const handleSubmit = (temp) => {

        const data = temp;
        let error = false;
        data.pob = placeOfBirth;

        if (!isNull(redirectParam) && redirectParam !== 'null' && redirectParam !== null
         && !isUndefined(redirectParam) && redirectParam !== '') {
            const validValues = ['horoscope-generated', 'consultations', 'dashboard', 'horoscope-details'];
            if (validValues.includes(redirectParam)) {
                data.redirectParam = redirectParam || 'horoscope-generated';
            }
        }

        if (placeOfBirth === '') {
            error = true;
            message.error(getTranslation(language, 'sign_up_second_screen_place_of_birth_validation', 'Please select Place of birth'));
        }

        if (!error) {
            dispatch(userActions.registerRequest({
                data,
            }));
        }
    };

    const handleForgotSubmit = (e) => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                // eslint-disable-next-line
                console.log('Received values of forgot form: ', values);
            }
        });
    };

    return (
        <div>
            <div className="signupform-wrap">
                <div className="signupform-container">
                    <Form onFinish={handleSubmit} layout="vertical">
                        <Row>
                            <Col xs={24}>
                                <Form.Item
                                    name="name"
                                    label={getTranslation(language, 'form_label_full_name', 'Full Name')}
                                    style={{ marginTop: '30px' }}
                                    rules={[
                                        {
                                            required: true,
                                            message: getTranslation(language, 'sign_up_second_screen_full_name_validation', 'Please enter Full name'),
                                        },
                                    ]}>
                                    <Input placeholder={getTranslation(language, 'form_label_full_name', 'Full Name')} />
                                </Form.Item>

                                <Form.Item
                                    label={getTranslation(language, 'form_label_email', 'Email')}
                                    name="email"
                                    style={{ marginTop: '30px' }}
                                    rules={[
                                        {
                                            type: 'email',
                                            message: getTranslation(language, 'sign_up_email_validation', 'Not a valid email.!'),
                                        },
                                        {
                                            required: true,
                                            message: getTranslation(language, 'sign_up_email_empty', 'Please input your email.'),
                                        },
                                    ]}>
                                    <Input placeholder={getTranslation(language, 'form_label_email', 'Email')} />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    label={getTranslation(language, 'form_label_password', 'Password')}
                                    rules={[
                                        { required: true,
                                            message: getTranslation(language, 'sign_up_password_empty', 'Please input your password.'),
                                        },
                                    ]}>
                                    <Input type="password" placeholder={getTranslation(language, 'form_label_password', 'Password')} />
                                </Form.Item>

                                <Form.Item
                                    name="confirm"
                                    label={getTranslation(language, 'form_label_repeat_password', 'Password')}
                                    dependencies={['password']}
                                    rules={[
                                        {
                                            required: true,
                                            message: getTranslation(language, 'sign_up_password_confirm', 'Please confirm your password.'),
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(new Error(getTranslation(language, 'sign_up_password_match', 'The two passwords that you entered do not match!')));
                                            },
                                        }),
                                    ]}>
                                    <Input type="password" placeholder={getTranslation(language, 'form_label_repeat_password', 'Password')} />
                                </Form.Item>

                                <Form.Item
                                    name="gender"
                                    label={getTranslation(language, 'form_label_gender', 'Gender')}
                                    style={{ marginTop: '30px' }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select gender!',
                                        },
                                    ]}>
                                    <Select style={{ float: 'left' }} placeholder={getTranslation(language, 'form_label_gender', 'Gender')}>
                                        <Select.Option value={1}>{getTranslation(language, 'form_gender_value_male', 'Male')}</Select.Option>
                                        <Select.Option value={2}>{getTranslation(language, 'form_gender_value_female', 'Female')}</Select.Option>
                                        <Select.Option value={3}>{getTranslation(language, 'form_gender_value_other', 'Other')}</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={getTranslation(language, 'form_date_and_time_of_birth_label', 'Date and time of birth')}
                                    name="dob"
                                    style={{ marginTop: '30px' }}
                                    rules={[
                                        {
                                            required: true,
                                            message: getTranslation(language, 'sign_up_second_screen_place_of_birth_validation', 'Please select Place of birth'),
                                        },
                                    ]}>
                                    <DatePicker showTime
                                        style={{ background: '#ff000000' }}
                                        format="YYYY-MM-DD HH:mm:00"
                                        placeholder={getTranslation(language, 'form_date_and_time_of_birth_label', 'Date and time of birth')} />
                                </Form.Item>
                                <p className="signupform-p">{getTranslation(language, 'form_place_of_birth_label', 'Place of birth')}</p>
                                <LocationSearchInput setLocation={(data) => {
                                    setPlaceOfBirth(data);
                                }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} className="margin-left-style">
                                <Form.Item
                                    name="agreement"
                                    valuePropName="checked"
                                    style={{
                                        marginTop: '50px',
                                    }}
                                    rules={[
                                        {
                                            validator: (_, value) => (value
                                                ? Promise.resolve()
                                                : Promise.reject(new Error(getTranslation(language, 'sign_up_second_screen_terms_and_conditions_validation', 'To proceed you have to agree to our terms and conditions')))),
                                        },
                                    ]}>
                                    <Checkbox style={{ color: '#fff', fontFamily: 'Noto Sans', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {getTranslation(language, 'form_agree_terms_label_part_1', 'Agree with the ')}
                                        <a href="https://www.prakashastrologer.com/terms-of-use/" style={{ fontFamily: 'Noto Sans', color: '#E1BF40' }}> {getTranslation(language, 'sign_up_form_terms_and_condition_label', 'Terms and Conditions')}  </a>
                                        {getTranslation(language, 'form_agree_terms_label_part_2', 'and')}
                                        <a href="https://www.prakashastrologer.com/privacy-policy/" style={{ fontFamily: 'Noto Sans', color: '#E1BF40' }}> {getTranslation(language, 'privacy_policy_link_text', 'Privacy Policy')}  </a>
                                    </Checkbox>
                                </Form.Item>

                                {user.isFetching ? (
                                    <Button type="primary" htmlType="submit" className="ant-btn-yellow" loading>

                                        {getTranslation(language, 'choose_moonsign_option_title', 'Choose your Moonsign')}
                                    </Button>
                                ) : (
                                    <Button type="primary" htmlType="submit" className="ant-btn-yellow">
                                        {getTranslation(language, 'choose_moonsign_option_title', 'Choose your Moonsign')}
                                    </Button>
                                )}

                            </Col>
                        </Row>
                    </Form>
                    {/* <Row style={{ marginTop: '20px' }}>
                        <Col xs={24}>
                            <Button onClick={() => setForgetPasswordModalVisible(true)}>
                                Forgot password
                            </Button>
                        </Col>
                    </Row> */}
                </div>
            </div>
            <Modal centered width={540} footer={null} visible={forgetPasswordModalVisible} onOk={() => { setForgetPasswordModalVisible(false); }} onCancel={() => { setForgetPasswordModalVisible(false); }}>
                <h4 className="h-3 text-center">{getTranslation(language, 'forgotten_password_screen_title', 'Forgot Password')}</h4>
                <div className="form-wrap">
                    <Form onSubmit={handleForgotSubmit} layout="vertical">
                        <Form.Item label={getTranslation(language, 'form_label_email', 'Email')} name="email" rules={[{ type: 'email', message: 'The input is not valid E-mail!' }, { required: true, message: 'Please input your E-mail!' }]}>
                            <Input />
                        </Form.Item>
                        <Button type="primary" htmlType="submit" className="wide">
                            {`${getTranslation(language, 'reset_password_button_title', 'Reset password')}`}
                        </Button>
                        <div>
                            <Button className="link-green btn-empty underlined" onClick={() => { setForgetPasswordModalVisible(false); }}>
                                {`${getTranslation(language, 'reset_password_button_title', 'cancel')}`}
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal>
        </div>
    );
};

export default SignupForm;
