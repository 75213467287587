import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Empty, Button, Spin, Modal } from 'antd';
import moment from 'moment';
import { isEmpty } from 'underscore';

import usePayment from '../../core/payment/usePayment';
import useUser from '../../core/user/useUser';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation, getSelectedTranslation } from '../../core/utils/helpers';
import Main from '../../components/layout/Main';
import imageDiamond from '../../assets/images/subscriptions/diamond.png';
import imageSilver from '../../assets/images/subscriptions/silver.png';
import imageGold from '../../assets/images/subscriptions/gold.png';
import history from '../../core/utils/history';

import './mySubscriptions.scss';

const MySubscriptions = () => {
    const { language } = useLanguage();
    const { getUserHistoricSubscriptionsRequest, payment, cancelSubscriptionRequest } = usePayment();
    const { user, getUserDetailsRequest } = useUser();

    useEffect(() => {
        // Update the document title using the browser API
        getUserHistoricSubscriptionsRequest();
        getUserDetailsRequest();
    }, []);

    useEffect(() => {
        getUserDetailsRequest();
    }, [payment?.userHistoricSubscriptions]);

    const handleSubmit = () => {
        history.push('/moonsign-select');
    };

    const errorModal = (titleMsg, contentMsg) => {

        Modal.error({
            title: titleMsg,
            content: contentMsg,
            width: 600,
        });
    };

    const showConfirm = (title, contentMsg, item) => {
        const cancelTxt = getTranslation(language, 'cancel_button_title', 'Cancel');
        const confirmTxt = getTranslation(language, 'confirm', 'Confirm');

        Modal.confirm({
            title,
            content: contentMsg,
            okText: confirmTxt,
            okType: 'danger',
            bodyStyle: {
                justifyContent: 'left',
                textAlign: 'left',
                alignItems: 'left',
                borderRadius: 10,
            },
            cancelText: cancelTxt,
            onOk() {
                cancelSubscriptionRequest(item);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const handleCancel = (item) => {
        if (item?.platform === 'ios' || item?.platform === 'android') {

            const title = item.platform === 'ios' ? getTranslation(language, 'error_ios_msg_title', 'Error Ios Msg title')
                : getTranslation(language, 'error_android_msg_title', 'Error android Msg title');

            const content = item.platform === 'ios' ? getTranslation(language, 'error_ios_msg_content', 'Error Ios Msg content')
                : getTranslation(language, 'error_android_msg_content', 'Error android Msg content');

            errorModal(title, content);
        } else {
            const content = getTranslation(language,
                'confirm_cancel_subscription', 'We are sorry to see you go, if you still wish to proceed in cancelling your subscription, please confirm below');

            const title = getTranslation(language,
                'confirm_cancel_subscription_title', 'Confirm cancel subscription');
            showConfirm(title, content, item);
        }
    };

    const renderPaymentPlanItem = (item, active) => {
        const paymentPlanTranslation = getSelectedTranslation(item?.payment_plan?.translations, language?.selectedLanguage?.id);
        const paymentPlanHoroscopeTranslation = getSelectedTranslation(item?.horoscope?.translations, language?.selectedLanguage?.id);
        return (
            <>
                <div className={`single-subscription ${active ? 'active' : ''}`} key={`subscription-id-${item.id}`}>
                    <Row>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} className="left-col">
                            {item?.payment_plan?.id === 1 ? <img src={imageSilver} alt="Silver" /> : null}
                            {item?.payment_plan?.id === 2 ? <img src={imageDiamond} alt="Diamond" /> : null}
                            {item?.payment_plan?.id === 3 ? <img src={imageGold} alt="Gold" /> : null}
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16} xl={16} className="right-col">
                            <div className="subscription-info-block">
                                <h4 className="package-title">{paymentPlanTranslation.title}{item.horoscope && item.horoscope.name ? ` (${paymentPlanHoroscopeTranslation.name})` : null}</h4>
                                {item?.status === 'active' && <p>{getTranslation(language, 'subscription_settings_screen_next_billing_date_title', 'Next billing date')} { item?.subscription_ended ? moment(item?.subscription_ended).format('Do MMM YYYY') : '' }</p>}
                                {item.platform === 'coupon'
                                    ? (
                                        <p>{getTranslation(language, 'subscription_settings_screen_cost_title', 'Cost:')} £<strike>{item.payment_plan?.amount}</strike> £0 {paymentPlanTranslation?.frequency_title}<br />
                                            {getTranslation(language, 'subscription_settings_screen_start_date_title', 'Start date:')}    {moment(item?.created_at).format('Do MMM YYYY')}<br />
                                            <span style={{ color: '#e1bf40' }}>{getTranslation(language, 'free_subscription_coupon_applied', 'Free Subscription Coupon Applied')} </span>
                                        </p>
                                    )
                                    : (
                                        <p>{getTranslation(language, 'subscription_settings_screen_cost_title', 'Cost:')} £{item.payment_plan?.amount} {paymentPlanTranslation?.frequency_title}<br />
                                            {getTranslation(language, 'subscription_settings_screen_start_date_title', 'Start date:')}    {moment(item?.created_at).format('Do MMM YYYY')}
                                        </p>
                                    )}
                                {item?.status === 'active'
                                    ? (
                                        <div onClick={() => handleCancel(item)} style={{ cursor: 'pointer', marginTop: 10, padding: 5, justifyContent: 'center', alignItems: 'center', fontSize: 12, color: 'red' }}>
                                            {getTranslation(language, 'cancel_subscription_label', 'Cancel subscription')}
                                        </div>
                                    )
                                    : null }

                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        );
    };

    const renderActiveSubscription = () => (
        user?.userData?.active_subscriptions.map(item => (
            renderPaymentPlanItem(item)
        ))
    );

    return (
        <Main
            title={<div>My Subscriptions - Prakash Astrologer</div>}
            headerTitle={<div><img style={{ height: 70, width: 70 }} src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg" alt="logo" /></div>}
            backLink={<Link to="/dashboard" className="backlink">{getTranslation(language, 'horoscopes_header_title', 'Horoscopes')}</Link>}
            menuWidth={350}
            menuPlacement="right"
            floatingHeader={false}
            displayRightMenu={false}
            showFooter={false}>
            <div className="my-subscriptions-content">

                <h3 className="page-subtitle yellow center">{getTranslation(language, 'subscription_settings_screen_active_subs_title', 'Active subscriptions')}</h3>
                {payment?.isFetching || user.isFetching
                    ? (
                        <div className="loaderSpacer">
                            <Spin size="large" />
                        </div>
                    )
                    : renderActiveSubscription()}

                { !user.isFetching && !payment?.isFetching && isEmpty(user?.userData?.active_subscriptions) ? <Empty description={getTranslation(language, 'subscription_no_active_subscriptions_found_title', 'There are no active records...')} /> : null }

                <h3 className="page-subtitle yellow center">{getTranslation(language, 'subscription_settings_screen_cancelled_subs_title', 'Cancelled subscriptions')}</h3>
                {payment.userHistoricSubscriptions.map(item => (
                    renderPaymentPlanItem(item)
                ))}

                {isEmpty(payment.userHistoricSubscriptions) ? <Empty description={getTranslation(language, 'subscription_no_historic_subscriptions_found_title', 'There are no historic records...')} /> : null }

                <Button onClick={handleSubmit} className="ant-btn-yellow" style={{ marginTop: '5vh' }} htmlType="submit" type="primary">
                    {getTranslation(language, 'subscription_settings_screen_add_package_button_title', 'Choose package')}
                </Button>
            </div>
        </Main>
    );
};

export default MySubscriptions;

MySubscriptions.propTypes = {};
