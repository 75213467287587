import React from 'react';
import PropTypes from 'prop-types';
import './yellowButtonInverted.scss';

const YellowButtonInverted = ({ children, ...props }) => (
    <button type="button" className="yellow-button-inverted" {...props}>{children}</button>
);

YellowButtonInverted.propTypes = {
    children: PropTypes.node,
};

YellowButtonInverted.defaultProps = {
    children: 'button text',
};

export default YellowButtonInverted;
