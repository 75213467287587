import React from 'react';
import { Row, Col, Layout } from 'antd';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';

import Menu from '../Menu';
import RightMenu from '../RightMenu';
import RightMenuConsultations from '../RightMenuConsultations';

import './header.css';

const Header = (props) => {
    const {
        menu, menuPlacement, menuWidth, floating,
        beforeHeader, afterHeader, displayRightMenu, displayRightMenuConsultations, headerTitle, backLink, scrollTo,
    } = props;

    const setBackLink = () => {

        if (window.location.pathname.includes('one-off-confirmation-mobile')
            || window.location.pathname.includes('one-off-confirmation-mobile-successfull')) {
            return <></>;
        }

        if (!isEmpty(backLink)) {
            return backLink;
        }
        return (<Menu items={menu} placement={menuPlacement} width={menuWidth} />);
    };

    return (
        <div className={`header ${floating ? 'floating-header' : ''}`}>
            {beforeHeader}
            <Layout.Header className="header__inner">
                <Row type="flex" align="middle" style={{ height: 'inherit' }}>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                        {setBackLink()}
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8} className="middle-col">
                        {!isEmpty(headerTitle) ? headerTitle : '<h3 className="header-logo-text">Prakash Astrologer</h3>'}
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                        <div className="d-flex justify-content-end">
                            {displayRightMenu === true ? <RightMenu items={menu} placement={menuPlacement} width={menuWidth} scrollTo={scrollTo} /> : <div className="right-menu-placeholder" />}
                            {displayRightMenuConsultations === true ? <RightMenuConsultations items={menu} width={menuWidth} scrollTo={scrollTo} /> : <div className="right-menu-placeholder" />}
                        </div>
                    </Col>
                </Row>
            </Layout.Header>
            {afterHeader}
        </div>
    );
};

Header.defaultProps = {
    toolbar: [],
    menu: [],
    backLink: null,
    logo: null,
    menuPlacement: 'left',
    menuWidth: 256,
    floating: false,
    beforeHeader: null,
    afterHeader: null,
    title: null,
    headerTitle: null,
    displayRightMenu: false,
    displayRightMenuConsultations: false,
    scrollTo: () => {},
};

Header.propTypes = {
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    toolbar: PropTypes.array,
    menu: PropTypes.array,
    backLink: PropTypes.object,
    logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    menuPlacement: PropTypes.string,
    menuWidth: PropTypes.number,
    floating: PropTypes.bool,
    beforeHeader: PropTypes.object,
    afterHeader: PropTypes.object,
    displayRightMenu: PropTypes.bool,
    displayRightMenuConsultations: PropTypes.bool,
    headerTitle: PropTypes.object,
    scrollTo: PropTypes.func,
};

export default Header;
