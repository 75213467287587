import { Record } from 'immutable';

const {
    GET_CONSULTATIONS_REQUEST,
    GET_CONSULTATIONS_SUCCESS,
    GET_CONSULTATIONS_FAILURE,

    SELECT_SINGLE_CONSULTATION_REQUEST,

    SAVE_CONSULTATION_REQUEST,
    SAVE_CONSULTATION_SUCCESS,
    SAVE_CONSULTATION_FAILURE,

    GET_SELECTED_CONSULTATION_REQUEST,
    GET_SELECTED_CONSULTATION_SUCCESS,
    GET_SELECTED_CONSULTATION_FAILURE,

} = require('./consultationActions').constants;

const InitialState = Record({
    isFetching: false,
    error: null,
    allConsultations: [],
    singleConsultation: {},
});

const initialState = new InitialState();

export default function consultationReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {

    case GET_SELECTED_CONSULTATION_REQUEST:
    case GET_CONSULTATIONS_REQUEST:
    case SAVE_CONSULTATION_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_CONSULTATIONS_SUCCESS: {
        return state.set('isFetching', false)
            .set('allConsultations', payload.data);
    }

    case SAVE_CONSULTATION_SUCCESS: {
        return state.set('isFetching', false);
    }

    case SELECT_SINGLE_CONSULTATION_REQUEST: {
        return state.set('isFetching', false)
            .set('singleConsultation', payload);
    }

    case GET_SELECTED_CONSULTATION_SUCCESS: {
        console.log('payload.dat', payload.data);
        return state.set('isFetching', false)
            .set('singleConsultation', payload.data);
    }

    case GET_SELECTED_CONSULTATION_FAILURE:
    case GET_CONSULTATIONS_FAILURE:
    case SAVE_CONSULTATION_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;

    }
}
