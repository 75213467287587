import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Main from '../../components/layout/Main';
import purchaseSuccessIcon from '../../assets/images/purchase_success_icon.svg';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation } from '../../core/utils/helpers';
import YellowThemeButton from '../../components/YellowThemeButton';
import './gemstoneEnquirySuccess.scss';

const GemstoneEnquirySuccess = () => {
    const { language } = useLanguage();
    const history = useHistory();

    const handleButtonClick = () => {
        history.push('/gemstones');
    };

    return (
        <Main
            className="purchase-life-events center-align"
            title={<div>Gemstone Enquiry</div>}
            headerTitle={(
                <div>
                    <img style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            menuWidth={350}
            menuPlacement="right"
            floatingHeader
            showHeader
            showFooter={false}
            backLink={(
                <div style={{ display: 'flex' }}>
                    <Link to="/dashboard" className="backlink" style={{ marginBottom: '24px' }} />
                </div>
            )}>
            <div className="gemstone-enquiry-confirmation">
                <div>
                    <img src={purchaseSuccessIcon} alt="success" style={{ marginBottom: '6px' }} />
                    <h1>{getTranslation(language, 'thank_you_for_your_enquiry', 'Thank you for your enquiry!')}</h1>
                    <p>{getTranslation(language, 'we_endeavour_to_get_back', 'We endeavour to get back to you within 28 days.')}</p>
                    <YellowThemeButton onClick={handleButtonClick}>
                        {getTranslation(language, 'back_to_home', 'Back to Home')}
                    </YellowThemeButton>
                </div>

            </div>
        </Main>
    );
};

export default GemstoneEnquirySuccess;
