/* eslint-disable  */

import DOMPurify from 'dompurify';
import React, { useEffect, createRef } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isUndefined, size } from 'underscore';
import { Link } from 'react-router-dom';
import Main from '../../components/layout/Main';
import useLanguage from '../../core/language/useLanguage';
import { getSelectedTranslation, getHoroscopeDataRange } from '../../core/utils/helpers';
// import history from '../../core/utils/history';
// import { saveOneOffPaymentRequest } from '../../core/payment/paymentActions';
import './singleHoroscope.scss';
import OneOffPurchaseButton from '../../components/OneOffPurchaseButton';

const SingleHoroscope = () => {

    const horoscope = useSelector(state => state.horoscope);

    const { singleHoroscope } = horoscope;
    const { language } = useLanguage();
    const partsLength = size(singleHoroscope?.horoscopeData?.parts);
    const [elRefs, setElRefs] = React.useState([]);
    const sanitizer = DOMPurify.sanitize;

    const horoscopeTranslation = getSelectedTranslation(singleHoroscope?.translations, language?.selectedLanguage?.id);
    const horoscopeData = getSelectedTranslation(singleHoroscope?.horoscopeData?.translations, language?.selectedLanguage?.id);

    const videoCode = singleHoroscope?.free_trial_days_left === 0 ? horoscopeData?.video_link_preview : horoscopeData?.video_link;

    useEffect(() => {
        // eslint-disable-next-line
        window.scrollTo(0, 0);
    }, []);

    React.useEffect(() => {
        // add or remove refs
        setElRefs((currentRef) => Array(partsLength)
            .fill()
            .map((_, i) => currentRef[i] || createRef()));
    }, [partsLength]);

    const getBackLink = () => {
        if (singleHoroscope?.current_period.cycle === 2) {
            return '/dashboard?tab=2';
        } 
        if (singleHoroscope?.current_period.cycle === 3) {
            return '/dashboard?tab=3';
        }
        return '/dashboard?tab=1';
    }

    const renderHoroscopePart = (partData, partDataIndex) => {
        const horoscopePartLanguage = getSelectedTranslation(partData?.translations, language?.selectedLanguage?.id);
        return (
            <div
                className="chapter-block"
                key={`single-part-${partData.id}`}
                ref={elRefs[partDataIndex]}>
                <h3>{horoscopePartLanguage?.name}</h3>
                {horoscopePartLanguage?.video_link
                    && (
                        <div className="video-block">
                            <iframe src={`https://www.youtube.com/embed/${horoscopePartLanguage?.video_link}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                        </div>
                    )}
                <div>{horoscopePartLanguage?.description}</div>
            </div>
        );
    };

    const scrollTo = (dataScrollTo) => {
        elRefs[dataScrollTo].current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <Main
            className="align-center"
            title={<div>Welcome</div>}
            headerTitle={(
                    <div>
                        <img style={{ height: 70, width: 70 }}
                            src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                            alt="logo" />
                    </div>
                )}
            menuWidth={350}
            menuPlacement="right"
            displayRightMenu
            floatingHeader
            showFooter={false}
            scrollTo={scrollTo}
            backLink={(
                    <div style={{ display: 'flex' }}>
                        <Link to={getBackLink} className="backlink" style={{ marginBottom: '24px' }} />
                    </div>
                )}
            >
            <div className="content single-horoscope-content">

                <h1 className="page-title">{horoscopeTranslation?.name}</h1>
                <h4 className="page-subtitle">{getHoroscopeDataRange(singleHoroscope)}</h4>
                {singleHoroscope?.is_user_subscribed === false && singleHoroscope?.has_one_off_purchase === false 
                    ? (
                        <div className="one-off-buy-btn-wrapper">
                            <OneOffPurchaseButton btnClass="" horoscopeContentFrequency={singleHoroscope?.current_period?.cycle} />
                        </div>
                    )
                    : null }
                {!isEmpty(videoCode) ? (
                    <div className="video-block">
                        <iframe src={`https://www.youtube.com/embed/${videoCode}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </div>
                ) : null }

                {(singleHoroscope?.free_trial_days_left === 0 || isUndefined(singleHoroscope?.free_trial_days_left)) && singleHoroscope?.is_user_subscribed === false && singleHoroscope?.has_one_off_purchase === false || singleHoroscope?.current_period.cycle === 3
                    ? (
                        <div>

                            <div className="horoscope-description-block" dangerouslySetInnerHTML={{ __html: sanitizer(horoscopeData?.description_preview) }} />
                        </div>
                    )
                    : null }

                {((singleHoroscope?.free_trial_days_left > 0 || singleHoroscope?.is_user_subscribed === true || singleHoroscope?.has_one_off_purchase === true) && singleHoroscope?.current_period.cycle !== 3) ? (
                    <div>
                        <div className="horoscope-description-block">{horoscopeData?.description}</div>
                        {singleHoroscope?.horoscopeData?.parts?.map((partItem, partIndex) => (
                            renderHoroscopePart(partItem, partIndex)
                        ))}
                    </div>
                )
                    : null }
            </div>
        </Main>
    );
};

export default SingleHoroscope;

SingleHoroscope.propTypes = {};
