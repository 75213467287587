import { Record } from 'immutable';
import { findIndex, isEmpty } from 'underscore';

const {
    GET_LANGUAGES_REQUEST,
    GET_LANGUAGES_SUCCESS,
    GET_LANGUAGES_FAILURE,

    SELECT_LANGUAGE_REQUEST,

} = require('./languageActions').constants;

const InitialState = Record({
    isFetching: null,
    languages: [],
    selectedLanguage: {},
    error: null,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function galleryReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case GET_LANGUAGES_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_LANGUAGES_SUCCESS: {

        let { selectedLanguage } = state;
        const languages = payload.data;

        // refreshing selected language content from the server
        if (selectedLanguage && selectedLanguage.id) {
            const selectedLanguageIndex = findIndex(languages, (val) => val.id === selectedLanguage.id);
            if (selectedLanguageIndex !== -1) {
                selectedLanguage = languages[selectedLanguageIndex];
            }
        }

        if (isEmpty(selectedLanguage) && languages && languages[0]) {
            const singleLang = languages[0];
            selectedLanguage = singleLang;
        }

        return state.set('isFetching', false)
            .set('selectedLanguage', selectedLanguage)
            .set('languages', languages);
    }

    case SELECT_LANGUAGE_REQUEST: {
        let { selectedLanguage } = state;
        const { languages } = state;

        const selectedLanguageIndex = findIndex(languages, (val) => val.id === payload);
        if (selectedLanguageIndex !== -1) {
            selectedLanguage = languages[selectedLanguageIndex];
        }

        return state.set('selectedLanguage', selectedLanguage);
    }

    case GET_LANGUAGES_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}
