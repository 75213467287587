import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    forgotPasswordRequest,
    logoutRequest,
    getUserDetailsRequest,
    profileUpdateRequest,
} from './userActions';

function useAuth() {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const handleForgotPasswordRequest = useCallback((payload) => {
        dispatch(forgotPasswordRequest(payload));
    }, []);

    const handleLogoutRequest = useCallback((payload) => {
        console.log('payload', payload);
        dispatch(logoutRequest(payload));
    }, []);

    const handleGetUserDetailsRequest = useCallback((payload) => {
        dispatch(getUserDetailsRequest(payload));
    }, []);

    const handleProfileUpdateRequest = useCallback((payload) => {
        dispatch(profileUpdateRequest(payload));
    },
    []);

    return {
        user,
        forgotPasswordRequest: handleForgotPasswordRequest,
        logoutRequest: handleLogoutRequest,
        getUserDetailsRequest: handleGetUserDetailsRequest,
        profileUpdateRequest: handleProfileUpdateRequest,
    };
}

export default useAuth;
