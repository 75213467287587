import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './lifeEventView.scss';
import { Link } from 'react-router-dom';
import Main from '../../components/layout/Main';
import leftArrow from '../../assets/images/left-nav-arrow.svg';
import rightArrow from '../../assets/images/right-nav-arrow.svg';
import { getSelectedTranslation, getHoroscopeDataRange } from '../../core/utils/helpers';
import useLanguage from '../../core/language/useLanguage';
import { selectSingleLifeEventRequest } from '../../core/horoscope/horoscopeActions';

const LifeEventView = () => {

    const horoscope = useSelector(state => state.horoscope);
    const { singleLifeEvent, singleHoroscope } = horoscope;
    const { language } = useLanguage();

    const dispatch = useDispatch();

    const lifeEventTranslation = getSelectedTranslation(singleLifeEvent?.translations, language?.selectedLanguage?.id);
    const singleHoroscopeTranslation = getSelectedTranslation(singleHoroscope?.translations, language?.selectedLanguage?.id);

    const selectedHoroscope = horoscope?.userActiveHoroscopes?.Yearly?.find((horoscopeObj) => horoscopeObj?.horoscope?.id === singleHoroscope?.id);
    const horoscopeParts = selectedHoroscope?.horoscope?.horoscopeData?.parts;
    const filteredParts = horoscopeParts?.filter((part) => part.user_has_access);
    const currentIndex = filteredParts?.findIndex((part) => part.id === singleLifeEvent?.id);

    const nextPart = filteredParts[currentIndex + 1];
    const previousPart = filteredParts[currentIndex - 1];
    const nextName = getSelectedTranslation(nextPart?.translations, language?.selectedLanguage?.id)?.name;
    const previousName = getSelectedTranslation(previousPart?.translations, language?.selectedLanguage?.id)?.name;

    useEffect(() => {
        window.scrollTo(0, 0);
        // avoid initial click problem
        dispatch(selectSingleLifeEventRequest(singleLifeEvent));
    }, []);

    if (!singleLifeEvent) {
        return null;
    }

    const handleNextClick = () => {
        dispatch(selectSingleLifeEventRequest(nextPart));
    };

    const handlePreviousClick = () => {
        dispatch(selectSingleLifeEventRequest(previousPart));
    };

    return (
        <Main
            className="purchase-life-events center-align"
            title={<div>Life Events</div>}
            headerTitle={(
                <div>
                    <img style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            menuWidth={350}
            menuPlacement="right"
            showHeader
            displayRightMenu
            showFooter={false}
            backLink={(
                <div style={{ display: 'flex' }}>
                    <Link to="/life-events" className="backlink" style={{ marginBottom: '24px' }} />
                </div>
            )}>
            <div className="display-life-event">
                {filteredParts.length > 1 ? (
                    <nav>
                        {currentIndex !== 0 ? (
                            <div onClick={handlePreviousClick}>
                                <img src={leftArrow} alt="go-one-before" />
                                <span>{previousName}</span>
                            </div>
                        ) : <div />}
                        {currentIndex < filteredParts.length - 1 ? (
                            <div onClick={handleNextClick}>
                                <span>{nextName}</span>
                                <img src={rightArrow} alt="go-one-after" />
                            </div>
                        ) : <div />}
                    </nav>
                ) : null}

                <h1>{lifeEventTranslation.name}</h1>
                <p>{singleHoroscopeTranslation.name} {getHoroscopeDataRange(singleHoroscope)}</p>
                <div className="content-wrapper">
                    {lifeEventTranslation.video_link && (
                        <div className="video-block">
                            <iframe src={`https://www.youtube.com/embed/${lifeEventTranslation?.video_link}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                        </div>
                    )}
                    {lifeEventTranslation?.description && <div>{lifeEventTranslation?.description}</div>}
                </div>
            </div>
        </Main>
    );
};

export default LifeEventView;
