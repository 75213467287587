import React, { useEffect } from 'react';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import history from '../../core/utils/history';
import Main from '../../components/layout/Main';
import LoginForm from '../../components/Forms/LoginForm';
// import BreadcrumbNav from '../../components/layout/BreadcrumbNav';
import useLanguage from '../../core/language/useLanguage';

import useUser from '../../core/user/useUser';
import { getTranslation } from '../../core/utils/helpers';

import './login.scss';

const Login = () => {
    const { language } = useLanguage();

    const { user } = useUser();

    const { getLanguagesRequest } = useLanguage();

    useEffect(() => {
        getLanguagesRequest();

        if (user?.isLoggedIn === true) {
            history.push('/dashboard');
        }
    }, []);

    // const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    // const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];
    // const navigation = [{ label: 'Item 1', link: '/abc' }, { label: 'Item 1', link: '/abc' }];

    return (
        <Main
            className="login"
            title={<div>Welcome</div>}
            headerTitle={<div>{getTranslation(language, 'login_screen_main_title', 'Sign In')}</div>}
            // menu={menu}
            menuWidth={350}
            menuPlacement="right"
            // toolbar={toolbar}
            // floatingFooter
            // floatingHeader
            // breadcrumb={navigation}
            showHeader={false}
            showFooter={false}
            backLink={(
                <Button onClick={() => console.log('Back')}>
                    <LeftOutlined />  {getTranslation(language, 'navigation_title_back', 'Back')}
                </Button>
            )}>
            {/* <BreadcrumbNav separator=">" navigation={navigation} /> */}
            <LoginForm />
        </Main>
    );
};

export default Login;

Login.propTypes = {};
