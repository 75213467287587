import { all } from 'redux-saga/effects';
import userSaga from './user/userSaga';
import horoscopeSaga from './horoscope/horoscopeSaga';
import paymentSaga from './payment/paymentSaga';
import languageSaga from './language/languageSaga';
import consultationsSaga from './consultation/consultationSaga';
import noticeboardSaga from './noticeboard/noticeboardSaga';
import gemstonesSaga from './gemstones/gemstonesSaga';

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        userSaga(),
        horoscopeSaga(),
        paymentSaga(),
        languageSaga(),
        consultationsSaga(),
        noticeboardSaga(),
        gemstonesSaga(),
    ]);
}
