/* eslint-disable quote-props */
import { put, call, takeEvery, select } from 'redux-saga/effects';
import { fetchApi } from '../utils/api';
import {
    getGemstonesSuccess,
    getGemstonesFailure,
    getGemstoneCategoriesSuccess,
    getGemstoneCategoriesFailure,
    getGemstoneEnquirySuccess,
    getGemstoneEnquiryFailure,
    getSingleGemstoneSuccess,
    getSingleGemstoneFailure,
} from './gemstonesActions';

const {
    GET_GEMSTONES_REQUEST,
    GET_GEMSTONE_CATEGORIES_REQUEST,
    GET_GEMSTONE_ENQUIRY_REQUEST,
    GET_SINGLE_GEMSTONE_REQUEST,
} = require('./gemstonesActions').constants;

function* getGemstones() {
    const { page, searchTerm, perPage, categoryId } = yield select(state => state.gemstones);
    const { selectedLanguage } = yield select(state => state.language);

    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: `/gemstone?category_id=${categoryId || ''}&page=${page + 1}&search=${searchTerm}&per_page=${perPage}&language_id=${selectedLanguage?.id}`,
        });
        yield put(getGemstonesSuccess(response));
    } catch (e) {
        console.log('error', e);
        // message.error(e.response ? e.response.data.message : e);
        yield put(getGemstonesFailure(e.response ? e.response.data.message : e));
    }
}

function* getGemstoneCategories() {
    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: '/gemstoneCategory',
        });
        yield put(getGemstoneCategoriesSuccess(response));
    } catch (e) {
        console.log('error', e);
        // message.error(e.response ? e.response.data.message : e);
        yield put(getGemstoneCategoriesFailure(e.response ? e.response.data.message : e));
    }
}

function* getGemstoneEnquiry({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/gemstone-enquiry/enquiry',
            body: payload,
        });
        yield put(getGemstoneEnquirySuccess(response));
    } catch (e) {
        console.log('error', e);
        // message.error(e.response ? e.response.data.message : e);
        yield put(getGemstoneEnquiryFailure(e.response ? e.response.data.message : e));
    }
}

function* getSingleGemstone({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: `/singleGemstone/${payload}`,
        });
        yield put(getSingleGemstoneSuccess(response));
    } catch (e) {
        console.log('error', e);
        // message.error(e.response ? e.response.data.message : e);
        yield put(getSingleGemstoneFailure(e.response ? e.response.data.message : e));
    }
}

export default function* consultationsSaga() {
    yield* [
        takeEvery(GET_GEMSTONES_REQUEST, getGemstones),
        takeEvery(GET_GEMSTONE_CATEGORIES_REQUEST, getGemstoneCategories),
        takeEvery(GET_GEMSTONE_ENQUIRY_REQUEST, getGemstoneEnquiry),
        takeEvery(GET_SINGLE_GEMSTONE_REQUEST, getSingleGemstone),
    ];
}
