import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { Spin } from 'antd';
import starIcon from '../../assets/images/star_icon.png';
import useLanguage from '../../core/language/useLanguage';
import { convertToTranslationIndex, getSelectedTranslation, getTranslation } from '../../core/utils/helpers';
import Main from '../../components/layout/Main';
import history from '../../core/utils/history';
import { getSelectedConsultationRequest } from '../../core/consultation/consultationActions';

import './consultationDetail.scss';

const ConsultationDetail = () => {
    const { language } = useLanguage();
    const consultation = useSelector(state => state.consultation);
    const { singleConsultation } = consultation;
    const [selectedConsultation, setSelectedConsultation] = useState([]);
    const sanitizer = DOMPurify.sanitize;
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        const endOfUrl = window.location.pathname.split('/').pop();
        dispatch(getSelectedConsultationRequest({ slug: endOfUrl, selectedLang: language?.selectedLanguage?.id }));
    }, []);

    useEffect(() => {
        if (!singleConsultation?.isFetching) {
            setSelectedConsultation(getSelectedTranslation(singleConsultation?.translations, language?.selectedLanguage?.id));
        }
    }, [singleConsultation]);

    const goToConsultationForm = () => {
        const endOfUrl = window.location.pathname.split('/').pop();
        history.push('/consultation-form', {
            prevPath: endOfUrl,
        });
    };

    return (
        <Main
            title={<div>Profile - Prakash Astrologer</div>}
            headerTitle={(
                <div>
                    <img style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            backLink={(
                <Link to="/consultations" className="backlink">
                    {getTranslation(language, 'settings_screen_consultations_link_title', 'Consultations')}
                </Link>
            )}
            menuWidth={350}
            menuPlacement="right"
            floatingHeader
            displayRightMenu={false}
            showFooter={false}>
            {consultation?.isFetching ? (
                <div className="loaderSpacer">
                    <Spin size="large" />
                </div>
            )
                : (
                    <div className="consultation-detail">
                        <h2 className="c-page-heading">{getTranslation(language, convertToTranslationIndex(selectedConsultation?.title), selectedConsultation?.title)}</h2>
                        <div className="c-image-box">
                            <img src={starIcon} alt="Figure of a star bound by a circle" />
                        </div>
                        <div className="c-description">
                            <p>{selectedConsultation?.description}</p>
                        </div>
                        <div className="c-buy-now">
                            <span className="c-title">{selectedConsultation?.title}</span>
                            <p className="c-brief" dangerouslySetInnerHTML={{ __html: sanitizer(selectedConsultation?.short_description) }} />
                            <button className="c-buy-button" type="button" onClick={goToConsultationForm}>{getTranslation(language, 'consultation_button_buy_now', 'Buy Now')}</button>
                        </div>
                    </div>
                ) }
        </Main>

    );
};

export default ConsultationDetail;
