import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Row, Col, DatePicker, message } from 'antd';
import moment from 'moment';
import LocationSearchInput from '../LocationSearchInput';
import '../../../pages/Horoscope/horoscope.scss';
import './profileForm.scss';
import useUser from '../../../core/user/useUser';
import { getTranslation } from '../../../core/utils/helpers';
import useLanguage from '../../../core/language/useLanguage';

const ProfileForm = () => {

    // const form = Form.useForm();
    const { language } = useLanguage();
    const { user, profileUpdateRequest } = useUser();
    const { userData } = user;

    const [fullName, setFullName] = useState(userData?.full_name || '');
    const [dateTime, setDateTime] = useState(userData?.dob || '');
    const [gender, setGender] = useState(userData?.gender || 0);
    const [location, setLocation] = useState(userData?.pob || '');

    function getWindowSize() {
        // eslint-disable-next-line
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    const [windowSize, setWindowSize] = useState(getWindowSize());

    const handleSubmit = (formData) => {
        let validate = true;
        let name = fullName;
        let dob = dateTime;
        let userGender = gender;

        if (fullName === '') {
            validate = false;
            message.info(getTranslation(language, 'profile_form_name_missing_error_label', 'Please enter Full name'));
        }

        if (validate && gender === 0) {
            validate = false;
            message.info(getTranslation(language, 'profile_form_gender_missing_error_label', 'Please select Gender'));
        }

        if (validate && dateTime === '') {
            validate = false;
            message.info(getTranslation(language, 'profile_form_dob_missing_error_label', 'Please select Date and time of birth'));
        }

        if (validate && location === '') {
            validate = false;
            message.info(getTranslation(language, 'profile_form_pob_missing_error_label', 'Please select Place of birth'));
        }

        if (validate) {

            if (formData.name && formData.name !== fullName) {
                name = formData.name;
                setFullName(formData.name);
            }
            if (formData.dob && formData.dob !== dateTime) {
                dob = formData.dob;
                setDateTime(formData.dob);
            }
            if (formData.gender && formData.gender !== gender) {
                userGender = formData.gender;
                setGender(formData.gender);
            }

            const data = {
                full_name: name,
                dob,
                pob: location,
                gender: userGender,
            };

            profileUpdateRequest({ data });

        }
    };

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        // eslint-disable-next-line
        window.addEventListener('resize', handleWindowResize);

        return () => {
            // eslint-disable-next-line
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <div>
            <div className="profileform-wrap">
                <div className="profileform-container">
                    <Form onFinish={handleSubmit} layout="vertical">
                        <Row>
                            <Col xs={24}>
                                <Form.Item
                                    name="name"
                                    label={getTranslation(language, 'form_label_full_name', 'Name')}
                                    style={{ marginTop: '30px' }}>
                                    <Input placeholder="ex. Jonathan Ive" defaultValue={fullName} />
                                </Form.Item>

                                <Form.Item
                                    name="gender"
                                    label={getTranslation(language, 'form_label_gender', 'Gender')}
                                    style={{ marginTop: '30px' }}>
                                    <Select style={{ float: 'left' }} placeholder="*Select your gender" defaultValue={gender}>
                                        <Select.Option value={1}>{getTranslation(language, 'form_gender_value_male', 'Male')}</Select.Option>
                                        <Select.Option value={2}>{getTranslation(language, 'form_gender_value_female', 'Female')}</Select.Option>
                                        <Select.Option value={3}>{getTranslation(language, 'form_gender_value_other', 'Other')}</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={getTranslation(language, 'form_date_and_time_of_birth_label', 'Date and time of birth')}
                                    name="dob"
                                    style={{ marginTop: '30px' }}>
                                    <DatePicker showTime
                                        style={{ background: '#ff000000' }}
                                        format="YYYY-MM-DD HH:mm:00"
                                        defaultValue={moment(dateTime, 'YYYY-MM-DD HH:mm:00')}
                                        placeholder={getTranslation(language, 'form_date_and_time_of_birth_label', 'Date and time of birth')} />
                                </Form.Item>
                                <p className="profileform-p">{getTranslation(language, 'form_place_of_birth_label', 'Place of birth')}</p>
                                <LocationSearchInput location={location}
                                    setLocation={(data) => {
                                        setLocation(data);
                                    }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} className="margin-left-style">

                                {user.isFetching ? (
                                    <Button type="primary" htmlType="submit" className="ant-btn-yellow" loading style={{ marginLeft: windowSize.innerWidth < 600 ? '0%' : '25%' }}>
                                        {getTranslation(language, 'profile_form_submit_button_label', 'Submit')}

                                    </Button>
                                ) : (
                                    <Button type="primary" htmlType="submit" className="ant-btn-yellow" style={{ marginLeft: windowSize.innerWidth < 600 ? '0%' : '25%' }}>
                                        {getTranslation(language, 'profile_form_submit_button_label', 'Submit')}

                                    </Button>
                                )}

                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default ProfileForm;
