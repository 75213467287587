import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import moment from 'moment';
import { isNull, isUndefined } from 'underscore';
import ellipse from '../../assets/images/ellipse.svg';
import { getSelectedTranslation, getTranslation } from '../../core/utils/helpers';
import Main from '../../components/layout/Main';
import './noticeboard.scss';

import {
    noticeboardReadStatusRequest,
} from '../../core/noticeboard/noticeboardActions';

const Noticeboard = () => {

    const noticeboard = useSelector(state => state.noticeboard);
    const language = useSelector(state => state.language);
    const dispatch = useDispatch();

    const markAsRead = () => {

        const unreadNoticeboard = noticeboard?.noticeboardData?.filter(item => {
            if (item?.read_at === null || item?.read_at === undefined) {
                return item.id;
            }
            return null;
        });

        if (unreadNoticeboard?.length > 0) {
            const filteredIds = unreadNoticeboard.map(item => item.id);
            dispatch(noticeboardReadStatusRequest({ filteredIds }));
        }
    };

    const handleOpenLink = (url) => {
        window.open(url, '_blank');
    };

    const returnLinkLabel = (link_label) => {

        if (link_label === null || link_label === undefined) {
            return getTranslation(language, 'link_label_text', 'Link');
        }

        return link_label;
    };

    const renderNoticeboard = (item, index) => {

        const horoscopeTranslation = getSelectedTranslation(item.translations, language?.selectedLanguage?.id);

        return (
            <div key={index} className={isNull(item?.read_at) || isUndefined(item?.read_at) ? 'c-box c-box-highlighted' : 'c-box'}>

                <div className="dot-icon">
                    {isNull(item?.read_at) || isUndefined(item?.read_at) ? (
                        <img src={ellipse} alt="dot" />
                    ) : null}
                </div>

                <div className="c-box-content">
                    <div className="c-box-title">
                        {horoscopeTranslation?.title}
                    </div>
                    <div className="c-box-subtitle">
                        {horoscopeTranslation?.description}
                    </div>
                    {horoscopeTranslation?.link && horoscopeTranslation?.link !== null && horoscopeTranslation?.link !== undefined
                        ? (
                            <div onClick={() => handleOpenLink(horoscopeTranslation?.link)} className="c-box-link">
                                {returnLinkLabel(horoscopeTranslation?.link_label)}
                            </div>
                        ) : null}
                    <div className="c-date">
                        {moment(item?.created_at).format('DD/MM/YYYY')}
                    </div>
                </div>
            </div>
        );
    };

    const renderNoticeboards = () => {
        if (noticeboard?.noticeboardData?.length > 0) {
            return noticeboard?.noticeboardData?.map((item, index) => (
                renderNoticeboard(item, index)
            ));
        }
        return null;
    };

    return (
        <Main
            title={<div>Profile - Prakash Astrologer</div>}
            headerTitle={(
                <div>
                    <img style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            backLink={(
                <Link to="/dashboard" className="backlink" />
            )}
            menuWidth={350}
            menuPlacement="right"
            floatingHeader
            displayRightMenu={false}
            showFooter={false}>
            <div className="noticeboard-detail">
                <div className="noticeboard-heading">
                    <div className="notice-head">
                        <h2 className="c-page-heading"> {getTranslation(language, 'noticeboard', 'Noticeboard')}</h2>
                    </div>
                    <div className="c-mark-all-read-head" onClick={() => markAsRead()}>
                        <p className="c-mark-all-read">{getTranslation(language, 'mark_all_as_read', 'Mark all as read')}</p>
                    </div>
                </div>
                {noticeboard?.isFetching
                    ? (
                        <div className="loaderSpacer">
                            <Spin size="large" />
                        </div>
                    )
                    : renderNoticeboards()}
            </div>

        </Main>

    );
};

export default Noticeboard;
