import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActiveHoroscopesRequest } from '../../core/horoscope/horoscopeActions';
import Main from '../../components/layout/Main';
import './lifeEventConfirmation.scss';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation, getSelectedTranslation } from '../../core/utils/helpers';
import purchaseSuccessIcon from '../../assets/images/purchase_success_icon.svg';
import YellowThemeButton from '../../components/YellowThemeButton';

const LifeEventConfirmation = () => {

    const horoscope = useSelector(state => state.horoscope);
    const { singleHoroscope } = horoscope;
    const { language } = useLanguage();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userActiveHoroscopesRequest({ refresh: true }));
    }, []);

    const translation = getSelectedTranslation(singleHoroscope?.translations, language?.selectedLanguage?.id);

    const handleButtonClick = () => {
        history.push('/life-events');
    };

    return (
        <Main
            className="purchase-life-events center-align"
            title={<div>Life Events</div>}
            headerTitle={(
                <div>
                    <img style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            menuWidth={350}
            menuPlacement="right"
            floatingHeader
            showHeader
            displayRightMenu
            showFooter={false}
            backLink={(
                <div style={{ display: 'flex' }}>
                    <Link to="/dashboard" className="backlink" style={{ marginBottom: '24px' }} />
                </div>
            )}>
            <div className="life-event-confirmation">
                <div>
                    <img src={purchaseSuccessIcon} alt="success" style={{ marginBottom: '6px' }} />
                    <h1>{getTranslation(language, 'thank_you_for_your_purchase', 'Thank you for your purchase!')}</h1>
                    <p>{getTranslation(language, 'view_life_event_message_part_1', 'You can now view your life events in your')} {translation?.name} {getTranslation(language, 'view_life_event_message_part_2', 'Yearly Horoscope section')}</p>
                    <YellowThemeButton onClick={handleButtonClick}>
                        {getTranslation(language, 'view_life__events', 'View Life Events')}
                    </YellowThemeButton>
                </div>

            </div>
        </Main>
    );
};
export default LifeEventConfirmation;
