import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useLanguage from '../../core/language/useLanguage';
import { getSelectedTranslation, getTranslation } from '../../core/utils/helpers';
import { saveConsultationRequest } from '../../core/consultation/consultationActions';
import Main from '../../components/layout/Main';
import history from '../../core/utils/history';

import './consultationConfirmation.scss';

const ConsultationConfirmation = () => {
    const dispatch = useDispatch();
    const { language } = useLanguage();
    const location = useLocation();

    const consultation = useSelector(state => state.consultation);
    const { singleConsultation } = consultation;
    const [selectedConsultation, setSelectedConsultation] = useState([]);

    const buyConsultation = () => {
        // this will reserve the consultation
        if (location?.state?.data) {
            dispatch(saveConsultationRequest(location?.state?.data));
            history.push({ pathname: '/enter-payment-details', state: { consultation_id: singleConsultation?.id, type: 'consultation', phone_number: location?.state?.data?.phone_number, questions: location?.state?.data?.questions, extra_questions: location?.state?.data?.extra_questions } });
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setSelectedConsultation(getSelectedTranslation(singleConsultation?.translations, language?.selectedLanguage?.id));
    }, []);

    return (
        <Main
            title={<div>Profile - Prakash Astrologer</div>}
            headerTitle={(
                <div>
                    <img style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            backLink={(
                <Link to="/consultations" className="backlink">
                    {getTranslation(language, 'settings_screen_consultations_link_title', 'Consultations')}
                </Link>
            )}
            menuWidth={350}
            menuPlacement="right"
            floatingHeader
            displayRightMenu={false}
            showFooter={false}>
            <div className="consultation-confirmation">
                <h2 className="c-page-heading">{getTranslation(language, 'consultations_confirmation_screen_title', 'Consultation Confirmation')}</h2>
                <div className="c-description">
                    <p>{selectedConsultation?.title}<br />
                        <p style={{ fontWeight: 'bold' }}>
                            {getTranslation(language, 'subscription_confirm_screen_price_label', 'Price:')} £{singleConsultation?.amount?.toFixed(2)}{'\n'}{'\n'}
                        </p>
                    </p>
                </div>
                <div className="c-description">
                    <p>{selectedConsultation?.description}</p>
                </div>
                <div className="custom-footer">
                    <a href="https://www.prakashastrologer.com/terms-of-use/" className="backlink" target="_blank" rel="noreferrer">
                        {getTranslation(language, 'terms_and_conditions_link_text', 'Terms and Conditions')}
                    </a>
                    {' '}{getTranslation(language, 'form_agree_terms_label_part_2', 'and')}{' '}
                    <a href="https://www.prakashastrologer.com/privacy-policy/" className="backlink" target="_blank" rel="noreferrer">
                        {getTranslation(language, 'privacy_policy_link_text', 'Privacy Policy')}
                    </a>
                </div>
                <div className="c-buy-now">
                    <button className="c-buy-button" type="button" onClick={buyConsultation}>Buy Now</button>
                </div>
            </div>
        </Main>

    );
};

export default ConsultationConfirmation;
