import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_PAYMENT_PLANS_REQUEST: null,
    GET_PAYMENT_PLANS_SUCCESS: null,
    GET_PAYMENT_PLANS_FAILURE: null,

    SELECT_PLAN_REQUEST: null,

    ADD_PAYMENT_CARD_REQUEST: null,
    ADD_PAYMENT_CARD_SUCCESS: null,
    ADD_PAYMENT_CARD_FAILURE: null,

    SAVE_PAYMENT_CARD_REQUEST: null,
    SAVE_PAYMENT_CARD_SUCCESS: null,
    SAVE_PAYMENT_CARD_FAILURE: null,

    BUY_SUBSCRIPTION_REQUEST: null,
    BUY_SUBSCRIPTION_SUCCESS: null,
    BUY_SUBSCRIPTION_FAILURE: null,

    BUY_CONSULTATION_REQUEST: null,
    BUY_CONSULTATION_SUCCESS: null,
    BUY_CONSULTATION_FAILURE: null,

    GET_USER_HISTORIC_SUBSCRIPTIONS_REQUEST: null,
    GET_USER_HISTORIC_SUBSCRIPTIONS_SUCCESS: null,
    GET_USER_HISTORIC_SUBSCRIPTIONS_FAILURE: null,

    GET_PAYMENT_CARDS_REQUEST: null,
    GET_PAYMENT_CARDS_SUCCESS: null,
    GET_PAYMENT_CARDS_FAILURE: null,

    APPLY_COUPON_CODE_REQUEST: null,
    APPLY_COUPON_CODE_SUCCESS: null,
    APPLY_COUPON_CODE_FAILURE: null,

    CANCEL_SUBSCRIPTION_REQUEST: null,
    CANCEL_SUBSCRIPTION_SUCCESS: null,
    CANCEL_SUBSCRIPTION_FAILURE: null,

    SAVE_ONE_OFF_PAYMENT_REQUEST: null,

    BUY_ONE_OFF_REQUEST: null,
    BUY_ONE_OFF_SUCCESS: null,
    BUY_ONE_OFF_FAILURE: null,

    ADD_LIFE_EVENT_REQUEST: null,

    BUY_LIFE_EVENT_REQUEST: null,
    BUY_LIFE_EVENT_SUCCESS: null,
    BUY_LIFE_EVENT_FAILURE: null,
});

export const {
    getPaymentPlansRequest,
    getPaymentPlansSuccess,
    getPaymentPlansFailure,
} = createActions(
    constants.GET_PAYMENT_PLANS_REQUEST,
    constants.GET_PAYMENT_PLANS_SUCCESS,
    constants.GET_PAYMENT_PLANS_FAILURE,
);

export const {
    selectPlanRequest,
} = createActions(
    constants.SELECT_PLAN_REQUEST,
);

export const {
    addPaymentCardRequest,
    addPaymentCardSuccess,
    addPaymentCardFailure,
} = createActions(
    constants.ADD_PAYMENT_CARD_REQUEST,
    constants.ADD_PAYMENT_CARD_SUCCESS,
    constants.ADD_PAYMENT_CARD_FAILURE,
);

export const {
    savePaymentCardRequest,
    savePaymentCardSuccess,
    savePaymentCardFailure,
} = createActions(
    constants.SAVE_PAYMENT_CARD_REQUEST,
    constants.SAVE_PAYMENT_CARD_SUCCESS,
    constants.SAVE_PAYMENT_CARD_FAILURE,
);

export const {
    buySubscriptionRequest,
    buySubscriptionSuccess,
    buySubscriptionFailure,
} = createActions(
    constants.BUY_SUBSCRIPTION_REQUEST,
    constants.BUY_SUBSCRIPTION_SUCCESS,
    constants.BUY_SUBSCRIPTION_FAILURE,
);

export const {
    getUserHistoricSubscriptionsRequest,
    getUserHistoricSubscriptionsSuccess,
    getUserHistoricSubscriptionsFailure,
} = createActions(
    constants.GET_USER_HISTORIC_SUBSCRIPTIONS_REQUEST,
    constants.GET_USER_HISTORIC_SUBSCRIPTIONS_SUCCESS,
    constants.GET_USER_HISTORIC_SUBSCRIPTIONS_FAILURE,
);

export const {
    getPaymentCardsRequest,
    getPaymentCardsSuccess,
    getPaymentCardsFailure,
} = createActions(
    constants.GET_PAYMENT_CARDS_REQUEST,
    constants.GET_PAYMENT_CARDS_SUCCESS,
    constants.GET_PAYMENT_CARDS_FAILURE,
);

export const {
    buyConsultationRequest,
    buyConsultationSuccess,
    buyConsultationFailure,
} = createActions(
    constants.BUY_CONSULTATION_REQUEST,
    constants.BUY_CONSULTATION_SUCCESS,
    constants.BUY_CONSULTATION_FAILURE,
);

export const {
    applyCouponCodeRequest,
    applyCouponCodeSuccess,
    applyCouponCodeFailure,
} = createActions(
    constants.APPLY_COUPON_CODE_REQUEST,
    constants.APPLY_COUPON_CODE_SUCCESS,
    constants.APPLY_COUPON_CODE_FAILURE,
);

export const {
    cancelSubscriptionRequest,
    cancelSubscriptionSuccess,
    cancelSubscriptionFailure,
} = createActions(
    constants.CANCEL_SUBSCRIPTION_REQUEST,
    constants.CANCEL_SUBSCRIPTION_SUCCESS,
    constants.CANCEL_SUBSCRIPTION_FAILURE,
);

export const {
    saveOneOffPaymentRequest,
} = createActions(
    constants.SAVE_ONE_OFF_PAYMENT_REQUEST,
);

export const {
    buyOneOffRequest,
    buyOneOffSuccess,
    buyOneOffFailure,
} = createActions(
    constants.BUY_ONE_OFF_REQUEST,
    constants.BUY_ONE_OFF_SUCCESS,
    constants.BUY_ONE_OFF_FAILURE,
);

export const {
    addLifeEventRequest,
} = createActions(
    constants.ADD_LIFE_EVENT_REQUEST,
);

export const {
    buyLifeEventRequest,
    buyLifeEventSuccess,
    buyLifeEventFailure,
} = createActions(
    constants.BUY_LIFE_EVENT_REQUEST,
    constants.BUY_LIFE_EVENT_SUCCESS,
    constants.BUY_LIFE_EVENT_FAILURE,
);
