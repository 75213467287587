import { Record } from 'immutable';

const {
    NOTICEBOARD_REQUEST,
    NOTICEBOARD_SUCCESS,
    NOTICEBOARD_FAILURE,

    NOTICEBOARD_READ_STATUS_REQUEST,
    NOTICEBOARD_READ_STATUS_SUCCESS,
    NOTICEBOARD_READ_STATUS_FAILURE,
} = require('./noticeboardActions').constants;

const {
    LOGOUT_REQUEST,
} = require('../user/userActions').constants;

const InitialState = Record({
    isFetching: false,
    error: null,
    noticeboardData: {},
    noticeboardDataAllread: true,
});

const initialState = new InitialState();

export default function noticeboardReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {

    case LOGOUT_REQUEST:
        return state.set('isFetching', true)
            .set('error', null)
            .set('noticeboardData', {})
            .set('noticeboardDataAllread', true);

    case NOTICEBOARD_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case NOTICEBOARD_READ_STATUS_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case NOTICEBOARD_SUCCESS: {
        const noticeboardData = payload && payload?.data?.noticeboards ? payload?.data?.noticeboards : {};
        const noticeboardDataAllread = payload && payload?.data?.noticeboards ? payload.data.allNoticeboardRead : true;

        return state.set('isFetching', false)
            .set('noticeboardData', noticeboardData)
            .set('noticeboardDataAllread', noticeboardDataAllread);
    }

    case NOTICEBOARD_READ_STATUS_SUCCESS:
        return state.set('isFetching', false);

    case NOTICEBOARD_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case NOTICEBOARD_READ_STATUS_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);
    default:
        return state;

    }
}
