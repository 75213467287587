import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    DELETE_TOKEN_REQUEST: null,
    DELETE_TOKEN_SUCCESS: null,

    SAVE_TOKEN_REQUEST: null,
    SAVE_TOKEN_SUCCESS: null,

    LOGIN_REQUEST: null,
    LOGIN_SUCCESS: null,
    LOGIN_FAILURE: null,

    PASSWORDLESS_SUCCESS: null,
    PASSWORDLESS_FAILURE: null,
    PASSWORDLESS_REQUEST: null,

    REGISTER_REQUEST: null,
    REGISTER_SUCCESS: null,
    REGISTER_FAILURE: null,

    LOGOUT_REQUEST: null,
    LOGOUT_SUCCESS: null,
    LOGOUT_FAILURE: null,

    SAVE_USER_DATA_REQUEST: null,

    FORGOT_PASSWORD_REQUEST: null,
    FORGOT_PASSWORD_SUCCESS: null,
    FORGOT_PASSWORD_FAILURE: null,

    RESET_PASSWORD_REQUEST: null,
    RESET_PASSWORD_SUCCESS: null,
    RESET_PASSWORD_FAILURE: null,

    GET_USER_DETAILS_REQUEST: null,
    GET_USER_DETAILS_SUCCESS: null,
    GET_USER_DETAILS_FAILURE: null,

    PROFILE_UPDATE_REQUEST: null,
    PROFILE_UPDATE_SUCCESS: null,
    PROFILE_UPDATE_FAILURE: null,

});

export const {
    deleteTokenRequest,
    deleteTokenSuccess,
} = createActions(
    constants.DELETE_TOKEN_REQUEST,
    constants.DELETE_TOKEN_SUCCESS,
);

export const {
    passwordlessRequest,
    passwordlessSuccess,
    passwordlessFailure,
} = createActions(
    constants.PASSWORDLESS_SUCCESS,
    constants.PASSWORDLESS_FAILURE,
    constants.PASSWORDLESS_REQUEST,
);

export const {
    saveTokenRequest,
    saveTokenSuccess,
} = createActions(
    constants.SAVE_TOKEN_REQUEST,
    constants.SAVE_TOKEN_SUCCESS,
);

export const {
    loginRequest,
    loginSuccess,
    loginFailure,
} = createActions(
    constants.LOGIN_REQUEST,
    constants.LOGIN_SUCCESS,
    constants.LOGIN_FAILURE,
);

export const {
    registerRequest,
    registerSuccess,
    registerFailure,
} = createActions(
    constants.REGISTER_REQUEST,
    constants.REGISTER_SUCCESS,
    constants.REGISTER_FAILURE,
);

export const {
    logoutRequest,
    logoutSuccess,
    logoutFailure,
} = createActions(
    constants.LOGOUT_REQUEST,
    constants.LOGOUT_SUCCESS,
    constants.LOGOUT_FAILURE,
);

export const {
    saveUserDataRequest,
} = createActions(
    constants.SAVE_USER_DATA_REQUEST,
);

export const {
    forgotPasswordRequest,
    forgotPasswordSuccess,
    forgotPasswordFailure,
} = createActions(
    constants.FORGOT_PASSWORD_REQUEST,
    constants.FORGOT_PASSWORD_SUCCESS,
    constants.FORGOT_PASSWORD_FAILURE,
);

export const {
    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordFailure,
} = createActions(
    constants.RESET_PASSWORD_REQUEST,
    constants.RESET_PASSWORD_SUCCESS,
    constants.RESET_PASSWORD_FAILURE,
);

export const {
    getUserDetailsRequest,
    getUserDetailsSuccess,
    getUserDetailsFailure,
} = createActions(
    constants.GET_USER_DETAILS_REQUEST,
    constants.GET_USER_DETAILS_SUCCESS,
    constants.GET_USER_DETAILS_FAILURE,
);

export const {
    profileUpdateRequest,
    profileUpdateSuccess,
    profileUpdateFailure,
} = createActions(
    constants.PROFILE_UPDATE_REQUEST,
    constants.PROFILE_UPDATE_SUCCESS,
    constants.PROFILE_UPDATE_FAILURE,
);
