import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message, notification } from 'antd';
import lockr from 'lockr';
import moment from 'moment';
import { fetchApi, fetchApiAuth } from '../utils/api';
import history from '../utils/history';

import {
    loginSuccess,
    loginFailure,

    logoutSuccess,

    registerSuccess,
    registerFailure,

    forgotPasswordSuccess,
    forgotPasswordFailure,

    getUserDetailsSuccess,
    getUserDetailsFailure,

    profileUpdateSuccess,
    profileUpdateFailure,

    resetPasswordSuccess,
    resetPasswordFailure,

} from './userActions';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = require('../constants').default;

const {
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    REGISTER_REQUEST,
    FORGOT_PASSWORD_REQUEST,
    GET_USER_DETAILS_REQUEST,
    PROFILE_UPDATE_REQUEST,
    RESET_PASSWORD_REQUEST,
} = require('./userActions').constants;

function saveSessionToken(action) {
    const authDate = new Date();
    lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
    lockr.set(AUTH_KEY, action.data.token);
}

function* register(action) {
    const dob = moment(action.payload.data.dob).format('YYYY-MM-DD HH:mm:00');
    console.log('action', action.payload.data);
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/user/complete-user-registration-web',
            body: { email: action.payload.data.email,
                password: action.payload.data.password,
                full_name: action.payload.data.name,
                gender: action.payload.data.gender,
                dob,
                pob: action.payload.data.pob,
            },
        });

        if (response.data.token) {
            yield call(saveSessionToken, response);
        }
        yield put(registerSuccess(response.data));
        if (action.payload.data?.redirectParam !== 'undefined' && action.payload.data?.redirectParam !== undefined
            && action.payload.data?.redirectParam !== null && action.payload.data?.redirectParam !== '') {
            history.push(`/${action.payload.data.redirectParam}`);
        } else {
            history.push('/horoscope-generated');
        }
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(registerFailure(e.response ? e.response.data.message : e));
    }
}

function* makeLoginRequest(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/login',
            body: { email: action.payload.username, password: action.payload.password },
        });

        if (response.data.token) {
            yield call(saveSessionToken, response);
            yield put(loginSuccess(response.data));
            if (action.payload?.redirectParam !== 'undefined' && action.payload?.redirectParam !== undefined
            && action.payload?.redirectParam !== null && action.payload?.redirectParam !== '') {
                history.push(`/${action.payload.redirectParam}`);
            } else {
                history.push('/dashboard');
            }
        } else {
            yield put(loginFailure('login request failed'));
        }
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(loginFailure(e.response ? e.response.data.message : e));
    }
}

function* logoutRequest(payload) {
    const reditectPath = payload?.payload?.path || null;
    yield put(logoutSuccess());
    localStorage.clear();
    // if reditectPath is null, then redirect to login page
    // else redirect to the path
    if (reditectPath !== 'undefined' && reditectPath !== null && reditectPath !== undefined && reditectPath !== '') {
        history.push(`/?redirect=${reditectPath}`);
    } else {
        history.push('/');
    }
}

function* forgotPassword(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/forgot-password',
            body: action.payload,
        });

        notification.success({ message: 'Success', description: 'Check your email for further instructions.' });
        yield put(forgotPasswordSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(forgotPasswordFailure(e.response ? e.response.data.message : e));
    }
}

function* getUserDetails() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'user/get-user-details',
        });

        yield put(getUserDetailsSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e?.message);
        yield put(getUserDetailsFailure(e.response ? e.response.data.message : e));
    }
}

function* profileUpdate(action) {
    const dob = moment(action.payload.data.dob).format('YYYY-MM-DD HH:mm:00');

    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/user/update-user-web',
            body: {
                full_name: action.payload.data.full_name,
                gender: action.payload.data.gender,
                dob,
                pob: action.payload.data.pob,
            },
        });
        console.log('Success', 'Settings Updated!');
        yield put(profileUpdateSuccess(response));
    } catch (e) {
        console.log('Error', 'Unable update user!');
        yield put(profileUpdateFailure(e.response ? e.response.data.message : e));
    }
}

function* resetPassword(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/reset-password',
            body: action.payload,
        });

        notification.success({ message: 'Success', description: 'Password reset successful.' });
        yield put(resetPasswordSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(resetPasswordFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeEvery(REGISTER_REQUEST, register),
        takeEvery(LOGIN_REQUEST, makeLoginRequest),
        takeEvery(LOGOUT_REQUEST, logoutRequest),
        takeEvery(FORGOT_PASSWORD_REQUEST, forgotPassword),
        takeEvery(GET_USER_DETAILS_REQUEST, getUserDetails),
        takeEvery(PROFILE_UPDATE_REQUEST, profileUpdate),
        takeEvery(RESET_PASSWORD_REQUEST, resetPassword),
    ]);
}
