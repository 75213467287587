import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNull, isUndefined } from 'underscore';
import lockr from 'lockr';
import { Spin } from 'antd';
import useLanguage from '../../core/language/useLanguage';
import { getSelectedTranslation, getTranslation, getHoroscopeData, getOneOffPrice, getPeriodCycleString } from '../../core/utils/helpers';
import Main from '../../components/layout/Main';
import { saveOneOffPaymentRequest, getPaymentPlansRequest } from '../../core/payment/paymentActions';
import history from '../../core/utils/history';
import './oneoffconfirmation.scss';
import leftAngle from '../../assets/images/left-angle.svg';
import { userActiveHoroscopesRequest } from '../../core/horoscope/horoscopeActions';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = require('../../core/constants').default;

const OneOffConfirmation = () => {
    const dispatch = useDispatch();
    const { language, getLanguagesRequest, selectLanguageRequest } = useLanguage();

    const location = useLocation();
    const payment = useSelector(state => state.payment);
    const { userActiveHoroscopes, isFetching } = useSelector(state => state.horoscope);
    const [singleHoroscope, setSingleHoroscope] = useState(location?.state?.data || payment?.selectedOneOffPaymentPlan);
    const [fetchedHoroscopeArr, setfetchedHoroscopeArr] = useState([]);
    const isOneOffConfirmationMobile = window.location.pathname.includes('one-off-confirmation-mobile');

    const urlParams = new URLSearchParams(window.location.search);
    const horoscopeKey = urlParams.get('horoscopeKey') || null; // this is the horoscope
    const authTokenKey = urlParams.get('key'); // this is the key

    // set single horocope data
    const setSingleHoroscopeData = (horoscopeArr) => {
        const horoscopePeriod = horoscopeArr[1]; // this is the horoscope period

        const periodString = getPeriodCycleString(horoscopePeriod);

        if (userActiveHoroscopes[periodString]) {
            // from active user horoscopes find the horoscope with the id
            const result = userActiveHoroscopes[periodString].find(item => +item?.horoscope?.id === +horoscopeArr[0]);
            setSingleHoroscope(result?.horoscope);
        }
    };

    useEffect(() => {

        if (isEmpty(language.languages)) {
            getLanguagesRequest();
        }
        // get all payment plans
        if (isEmpty(payment.plans)) {
            dispatch(getPaymentPlansRequest());
        }

        // if user came from mobile
        if (isOneOffConfirmationMobile) {

            // Save Auth Token and date
            if (authTokenKey && !isNull(authTokenKey)) {
                const authDate = new Date();
                lockr.set(AUTH_KEY, authTokenKey);
                lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
            }

            dispatch(userActiveHoroscopesRequest({ refresh: true }));
        }

    }, []);

    // set single horocope data
    useEffect(() => {
        if (isOneOffConfirmationMobile) {
            if (!isUndefined(horoscopeKey) || horoscopeKey !== 'null' || !isNull(horoscopeKey) || !isEmpty(horoscopeKey) || horoscopeKey !== '') {
                const horoscopeArray = horoscopeKey?.split('-'); // split the url
                if (!isEmpty(userActiveHoroscopes) && !isEmpty(horoscopeArray)) {
                    setfetchedHoroscopeArr(horoscopeArray);
                    setSingleHoroscopeData(horoscopeArray);
                }
            }

        }
    }, [userActiveHoroscopes]);

    useEffect(() => {
        if (!isEmpty(language?.languages) && isOneOffConfirmationMobile) {
            const selectedLanguage = fetchedHoroscopeArr[2] ?? 1; // this is the language id
            const lang = language?.languages.find(langItem => +langItem?.id === +selectedLanguage);
            selectLanguageRequest(lang?.id);
        }
    }, [language?.languages]);

    const horoscopeTranslation = getSelectedTranslation(singleHoroscope?.translations, language?.selectedLanguage?.id);

    const handleGoBack = () => {
        history.push('/horoscope-details');
    };

    const buyOneOffPlan = () => {
        if (singleHoroscope) {
            const type = isOneOffConfirmationMobile ? 'oneOffPurchaseMobile' : 'oneOffPurchase';
            let oneOffPaymentRequestData = location?.state?.data;
            if (isOneOffConfirmationMobile) {
                oneOffPaymentRequestData = singleHoroscope;
            }
            dispatch(saveOneOffPaymentRequest(oneOffPaymentRequestData));
            history.push({ pathname: '/enter-payment-details', state: { type } });
        }
    };

    const backBtn = () => (
        <button style={{ backgroundColor: 'transparent', border: 0 }}
            onClick={() => handleGoBack()}
            type="button">
            <img src={leftAngle} alt="go back" />
        </button>
    );

    const gethoroscopePeriodType = () => {
        if (+singleHoroscope?.current_period?.cycle === 1) {
            return getTranslation(language, 'horoscopes_listing_screen_tab_weekly_title', 'Weekly');
        } if (+singleHoroscope?.current_period?.cycle === 2) {
            return getTranslation(language, 'horoscopes_listing_screen_tab_monthly_title', 'Monthly');
        } if (+singleHoroscope?.current_period?.cycle === 3) {
            return getTranslation(language, 'horoscopes_listing_screen_tab_yearly_title', 'Yearly');
        }
        return '';
    };

    return (
        <Main
            title={<div>Profile - Prakash Astrologer</div>}
            headerTitle={(
                <div>
                    <img style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            backLink={backBtn()}
            menuWidth={350}
            menuPlacement="right"
            floatingHeader
            displayRightMenu={false}
            showFooter={false}>
            <div className="oneoffconfirmation-confirmation">
                {isFetching
                    ? (
                        <div className="spinner-style">
                            <Spin size="large" />
                        </div>
                    )
                    : (
                        <>
                            <h2 className="c-page-heading">{getTranslation(language, 'consultations_confirmation_screen_title', 'Consultation Confirmation')}</h2>
                            <div className="c-description-container">
                                <p className="c-title">
                                    {horoscopeTranslation?.name}
                                </p>
                                <p className="c-sub-title">
                                    {`${gethoroscopePeriodType()} ${getTranslation(language, 'horoscope', 'Horoscope')}`}: {getHoroscopeData(singleHoroscope)}
                                </p>
                                <p className="c-price">
                                    {getTranslation(language, 'subscription_settings_screen_cost_title', 'Price')}: £{getOneOffPrice(singleHoroscope)}
                                </p>
                                <div className="c-line" />
                                <p className="c-description">
                                    {getTranslation(language, 'one_off_confirmation',
                                        'You will have access to the horoscope'
                        + 'through the app while available. '
                        + 'Also, you will receive a PDF file via email with all information.')}
                                </p>

                                <div className="c-buy-now">
                                    <button className="c-buy-button" type="button" onClick={() => buyOneOffPlan()}>
                                        {getTranslation(language, 'buy_now', 'Buy Now')}
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
            </div>
        </Main>

    );
};

export default OneOffConfirmation;
