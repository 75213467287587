import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Layout } from 'antd';

import BreadcrumbNav from '../BreadcrumbNav';
import Header from '../Header';
import Footer from '../Footer';
import './main.css';
import icon from '../../../assets/favicon.ico';

const {
    APP_NAME,
} = require('../../../core/constants').default;

const Main = (props) => {

    const {
        children, title, toolbar, breadcrumb, className, menu, backLink,
        showHeader, beforeHeader, afterHeader, floatingHeader, headerTitle,
        showFooter, beforeFooter, afterFooter, floatingFooter, footerContent,
        menuPlacement, menuWidth, displayRightMenu, displayRightMenuConsultations, scrollTo,
    } = props;

    return (
        <div className={`page-wrap ${className}`}>
            <Helmet
                title={typeof title === 'object' ? title.props.children : title}
                titleTemplate={`%s - ${APP_NAME}`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: `${APP_NAME} website` },
                    { property: 'og:type', content: 'website' },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            {showHeader
                ? (
                    <Header
                        beforeheader={beforeHeader}
                        afterHeader={afterHeader}
                        floating={floatingHeader}
                        headerTitle={headerTitle}
                        toolbar={toolbar}
                        backLink={backLink}
                        menu={menu}
                        menuPlacement={menuPlacement}
                        menuWidth={menuWidth}
                        displayRightMenu={displayRightMenu}
                        displayRightMenuConsultations={displayRightMenuConsultations}
                        scrollTo={scrollTo} />
                )
                : null}
            <Layout.Content className="main-content">
                {breadcrumb ? <BreadcrumbNav navigation={breadcrumb} /> : null}
                {children}
            </Layout.Content>
            {showFooter
                ? (
                    <Footer
                        beforeFooter={beforeFooter}
                        afterFooter={afterFooter}
                        floating={floatingFooter}>
                        {footerContent}
                    </Footer>
                )
                : null}
        </div>
    );
};

Main.defaultProps = {
    afterFooter: null,
    afterHeader: null,
    backLink: null,
    beforeFooter: null,
    beforeHeader: null,
    breadcrumb: null,
    children: false,
    className: '',
    floatingFooter: false,
    floatingHeader: false,
    footerContent: null,
    headerTitle: null,
    logo: null,
    menu: [],
    menuPlacement: 'left',
    menuWidth: 256,
    showFooter: true,
    showHeader: true,
    displayRightMenu: false,
    displayRightMenuConsultations: false,
    toolbar: [],
    scrollTo: () => {},
};

Main.propTypes = {
    afterFooter: PropTypes.object,
    afterHeader: PropTypes.object,
    backLink: PropTypes.object,
    beforeFooter: PropTypes.object,
    beforeHeader: PropTypes.object,
    breadcrumb: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    children: PropTypes.node,
    className: PropTypes.string,
    floatingFooter: PropTypes.bool,
    floatingHeader: PropTypes.bool,
    footerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    headerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    menu: PropTypes.array,
    menuPlacement: PropTypes.string,
    menuWidth: PropTypes.number,
    showFooter: PropTypes.bool,
    showHeader: PropTypes.bool,
    title: PropTypes.node.isRequired,
    toolbar: PropTypes.array,
    displayRightMenu: PropTypes.bool,
    displayRightMenuConsultations: PropTypes.bool,
    scrollTo: PropTypes.func,
};

export default Main;
