import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_LANGUAGES_REQUEST: null,
    GET_LANGUAGES_SUCCESS: null,
    GET_LANGUAGES_FAILURE: null,

    SELECT_LANGUAGE_REQUEST: null,
});

export const {
    getLanguagesRequest,
    getLanguagesSuccess,
    getLanguagesFailure,
} = createActions(
    constants.GET_LANGUAGES_REQUEST,
    constants.GET_LANGUAGES_SUCCESS,
    constants.GET_LANGUAGES_FAILURE,
);

export const {
    selectLanguageRequest,
} = createActions(
    constants.SELECT_LANGUAGE_REQUEST,
);
