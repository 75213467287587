import { combineReducers } from 'redux';
// import { message } from 'antd';
import global from './global/globalReducer';
import user from './user/userReducer';
import horoscope from './horoscope/horoscopeReducer';
import payment from './payment/paymentReducer';
import language from './language/languageReducer';
import consultation from './consultation/consultationReducer';
import noticeboard from './noticeboard/noticeboardReducer';
import gemstones from './gemstones/gemstonesReducer';

/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */
const appReducers = combineReducers({
    // ... your other reducers here ...
    global,
    user,
    horoscope,
    payment,
    language,
    consultation,
    noticeboard,
    gemstones,
});

const rootReducer = (state, action) => {
    // ... your other reducers here ...
    if ((action.type).indexOf('_FAILURE') !== -1) {
        // message.error(action.payload);
    }

    return appReducers(state, action);
};

export default rootReducer;
