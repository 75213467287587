import { Record } from 'immutable';

const {
    GET_GEMSTONES_REQUEST,
    GET_GEMSTONES_SUCCESS,
    GET_GEMSTONES_FAILURE,

    GET_GEMSTONE_CATEGORIES_REQUEST,
    GET_GEMSTONE_CATEGORIES_SUCCESS,
    GET_GEMSTONE_CATEGORIES_FAILURE,

    GET_GEMSTONE_ENQUIRY_REQUEST,
    GET_GEMSTONE_ENQUIRY_SUCCESS,
    GET_GEMSTONE_ENQUIRY_FAILURE,

    GET_SINGLE_GEMSTONE_REQUEST,
    GET_SINGLE_GEMSTONE_SUCCESS,
    GET_SINGLE_GEMSTONE_FAILURE,

    RESET_GEMSTONES_DATA,

    SET_SEARCH_TERM,

    SET_CATEGORY_ID,

    SET_SINGLE_GEMSTONE,

    RESET_CATEGORY_ID,

    SET_VISIT_FROM_APP,
} = require('./gemstonesActions').constants;

const InitialState = Record({
    isFetching: false,
    error: null,
    gemstones: [],
    gemstoneCategories: [],
    page: 0,
    searchTerm: '',
    perPage: 24,
    categoryId: 0,
    lastPage: null,
    lastUpdated: null,
    totalGemstones: 0,
    singleGemstone: {},
    visitFromApp: false,
});

const initialState = new InitialState();

export default function gemstonesReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {

    case GET_GEMSTONES_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_GEMSTONES_SUCCESS: {
        const currentPage = payload.data?.current_page;
        const lastPage = payload.data?.last_page;

        if (currentPage > state.get('page') && currentPage <= lastPage) {
            const updatedGemstones = state.get('gemstones').concat(payload.data?.data);

            return state.set('isFetching', false)
                .set('gemstones', updatedGemstones)
                .set('page', currentPage)
                .set('lastPage', lastPage)
                .set('lastUpdated', new Date())
                .set('totalGemstones', payload.data?.total);
        }
        return state.set('isFetching', false);
    }

    case GET_GEMSTONES_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case GET_GEMSTONE_CATEGORIES_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_GEMSTONE_CATEGORIES_SUCCESS:
        return state.set('isFetching', false)
            .set('gemstoneCategories', payload.data);

    case GET_GEMSTONE_CATEGORIES_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case GET_GEMSTONE_ENQUIRY_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_GEMSTONE_ENQUIRY_SUCCESS:
        return state.set('isFetching', false)
            .set('error', null);

    case GET_GEMSTONE_ENQUIRY_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case GET_SINGLE_GEMSTONE_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_SINGLE_GEMSTONE_SUCCESS:
        return state.set('isFetching', false)
            .set('singleGemstone', payload.data);

    case GET_SINGLE_GEMSTONE_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case RESET_GEMSTONES_DATA:
        return state.set('isFetching', false)
            .set('error', null)
            .set('gemstones', [])
            .set('page', 0)
            .set('lastPage', null);

    case SET_SEARCH_TERM: {
        return state.set('searchTerm', payload);
    }

    case SET_CATEGORY_ID:
        return state.set('categoryId', payload);

    case SET_SINGLE_GEMSTONE:
        return state.set('singleGemstone', payload);

    case RESET_CATEGORY_ID:
        return state.set('categoryId', 0);

    case SET_VISIT_FROM_APP:
        return state.set('visitFromApp', payload);

    default:
        return state;
    }
}
