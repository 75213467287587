import React from 'react';
import PropTypes from 'prop-types';
import './yellowThemeButton.scss';

const YellowThemeButton = ({ children, ...props }) => (
    <button type="button" className="theme-yellow-button" {...props}>{children}</button>
);

YellowThemeButton.propTypes = {
    children: PropTypes.node,
};

YellowThemeButton.defaultProps = {
    children: 'button text',
};

export default YellowThemeButton;
