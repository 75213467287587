import React from 'react';
import Countdown from 'react-countdown';
import { PropTypes } from 'prop-types';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation } from '../../core/utils/helpers';

import './countDownTimer.scss';

const CountDownTimer = ({ timerLeft }) => {

    const { language } = useLanguage();

    const Completionist = () => <span />;

    const leftPad = (number, targetLength) => {
        let output = `${number}`;
        while (output.length < targetLength) {
            output = `0${output}`;
        }
        return output;
    };

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
        // Render a completed state
            return <Completionist />;
        }
        // Render a countdown
        return (
            <div className="counterBlock">
                <div className="counterTimeBlock">
                    <div className="counterTimeNumberBlock">
                        {leftPad(days, 2)}
                    </div>
                    <div className="counterTimeLabelBlock">
                        {`${getTranslation(language, 'countdown_time_day_title', 'Days')}`}
                    </div>
                </div>
                <div className="counterTimeBlock">
                    <div className="counterTimeNumberBlock">
                        {leftPad(hours, 2)}
                    </div>
                    <div className="counterTimeLabelBlock">
                        {`${getTranslation(language, 'countdown_time_hours_title', 'Hours')}`}

                    </div>
                </div>
                <div className="counterTimeBlock">
                    <div className="counterTimeNumberBlock">
                        {leftPad(minutes, 2)}
                    </div>
                    <div className="counterTimeLabelBlock">
                        {`${getTranslation(language, 'countdown_time_minute_title', 'Minutes')}`}

                    </div>
                </div>
                <div className="counterTimeBlock">
                    <div className="counterTimeNumberBlock">
                        {leftPad(seconds, 2)}
                    </div>
                    <div className="counterTimeLabelBlock">
                        {`${getTranslation(language, 'countdown_time_second_title', 'Seconds')}`}

                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            {timerLeft > 0 ? <p>{`${getTranslation(language, 'countdown_free_trial_title', 'Free trial ends in')}`} </p> : null}
            <Countdown
                date={Date.now() + (timerLeft * 1000)}
                intervalDelay={0}
                precision={3}
                renderer={renderer} />
        </div>
    );
};

CountDownTimer.propTypes = {
    timerLeft: PropTypes.number,
};

CountDownTimer.defaultProps = {
    timerLeft: 604800,
};

export default CountDownTimer;
