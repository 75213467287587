import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './gemstoneCard.scss';
import { useDispatch } from 'react-redux';
import { getTranslation, getSelectedTranslation } from '../../core/utils/helpers';
import { setSingleGemstone } from '../../core/gemstones/gemstonesActions';

const GemstoneCard = ({ gemstone, language }) => {
    const [isHovered, setIsHovered] = useState(false);

    const dispatch = useDispatch();

    const { selectedLanguage } = language;
    const translation = getSelectedTranslation(gemstone?.translations, selectedLanguage?.id);

    const handleClick = () => {
        dispatch(setSingleGemstone(gemstone));
        if (gemstone?.slug) {
            window.open(`/gemstone/${gemstone?.slug}`, '_blank');
        }
    };

    return (
        <div className="gemstone-card" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={handleClick}>
            <div className="gemstone-card">
                <div className="gemstone-image">
                    <img src={gemstone.mediaUrl} alt="gemstone" />
                    {isHovered && <button type="button" className="view-button">{getTranslation(language, 'view', 'View')}</button>}
                </div>
                <p className="gemstone-name">{translation?.title}</p>
            </div>
        </div>
    );
};

GemstoneCard.propTypes = {
    gemstone: PropTypes.object.isRequired,
    language: PropTypes.object.isRequired,
};

GemstoneCard.defaultProps = {

};

export default GemstoneCard;
