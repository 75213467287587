import React, { useState } from 'react';
// import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
    geocodeByAddress,
    // getLatLng,
} from 'react-places-autocomplete';

import './locationSearchInput.scss';
import useLanguage from '../../../core/language/useLanguage';
import { getTranslation } from '../../../core/utils/helpers';

const LocationSearchInput = ({ setLocation, location }) => {

    const { language } = useLanguage();
    const [address, setAddress] = useState('');

    const handleChange = (place) => {
        setAddress(place);
    };

    console.log('location', location);

    const handleSelect = (place) => {
        setAddress(place); geocodeByAddress(place)
            .then(results => {
                const response = {
                    location:
                    {
                        formatted_address: results[0].formatted_address,
                        geometry: {
                            location: {
                                lat: results[0].geometry.location.lat(),
                                lng: results[0].geometry.location.lng(),
                            },
                        },
                    },
                };
                setAddress(response.location.formatted_address);
                setAddress(response.location.formatted_address);
                setLocation(response);

            })
            .then(latLng => console.log('Success', latLng))
            .catch(error => console.error('Error', error));
    };

    const searchOptions = {
        types: ['(cities)'],
    };

    let placeholder = getTranslation(language, 'form_place_of_birth_label', 'Search Places ...');
    if (location) {
        placeholder = location;
    }

    return (
        <PlacesAutocomplete
            value={address}
            searchOptions={searchOptions}
            onChange={handleChange}
            onSelect={handleSelect}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <input
                        {...getInputProps({
                            placeholder,
                            className: 'location-search-input ant-input',
                        })} />

                    <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active
                                ? 'location-item suggestion-item--active'
                                : 'location-item suggestion-item';
                            // inline style for demonstration purpose
                            const baseStyle = suggestion.active
                                ? { background: '#e1bf40', cursor: 'pointer' }
                                : { background: 'transparent', cursor: 'pointer' };

                            const secondStyle = {
                                padding: 10,
                                color: '#fff',
                            };

                            const style = {
                                ...baseStyle,
                                ...secondStyle,
                            };

                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}>
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>

                </div>
            )}
        </PlacesAutocomplete>
    );

};
LocationSearchInput.propTypes = {
    setLocation: () => {},
    location: null,
};

LocationSearchInput.defaultProps = {
    setLocation: PropTypes.func,
    location: PropTypes.any,
};
export default LocationSearchInput;
