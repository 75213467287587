import qs from 'qs';
import axios from 'axios';

const stripeUrl = 'https://api.stripe.com/v1/';
const secretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;

const createCardToken = ({ card_number, card_exp_month, card_exp_year, card_cvc, holders_name }) => {
    const cardDetails = qs.stringify({
        'card[number]': card_number,
        'card[exp_month]': card_exp_month,
        'card[exp_year]': card_exp_year,
        'card[cvc]': card_cvc,
        'card[name]': holders_name,
    });

    return axios({
        method: 'post',
        url: `${stripeUrl}tokens`,
        data: cardDetails,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
            Authorization: `Bearer ${secretKey}`,
        },
    });
};

export default createCardToken;
