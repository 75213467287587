import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_GEMSTONES_REQUEST: null,
    GET_GEMSTONES_SUCCESS: null,
    GET_GEMSTONES_FAILURE: null,

    GET_GEMSTONE_CATEGORIES_REQUEST: null,
    GET_GEMSTONE_CATEGORIES_SUCCESS: null,
    GET_GEMSTONE_CATEGORIES_FAILURE: null,

    GET_GEMSTONE_ENQUIRY_REQUEST: null,
    GET_GEMSTONE_ENQUIRY_SUCCESS: null,
    GET_GEMSTONE_ENQUIRY_FAILURE: null,

    GET_SINGLE_GEMSTONE_REQUEST: null,
    GET_SINGLE_GEMSTONE_SUCCESS: null,
    GET_SINGLE_GEMSTONE_FAILURE: null,

    RESET_GEMSTONES_DATA: null,

    SET_SEARCH_TERM: null,

    SET_CATEGORY_ID: null,

    SET_SINGLE_GEMSTONE: null,

    RESET_CATEGORY_ID: null,

    SET_VISIT_FROM_APP: null,
});

export const {
    getGemstonesRequest,
    getGemstonesSuccess,
    getGemstonesFailure,
} = createActions(
    constants.GET_GEMSTONES_REQUEST,
    constants.GET_GEMSTONES_SUCCESS,
    constants.GET_GEMSTONES_FAILURE,
);

export const {
    getGemstoneCategoriesRequest,
    getGemstoneCategoriesSuccess,
    getGemstoneCategoriesFailure,
} = createActions(
    constants.GET_GEMSTONE_CATEGORIES_REQUEST,
    constants.GET_GEMSTONE_CATEGORIES_SUCCESS,
    constants.GET_GEMSTONE_CATEGORIES_FAILURE,
);

export const {
    getGemstoneEnquiryRequest,
    getGemstoneEnquirySuccess,
    getGemstoneEnquiryFailure,
} = createActions(
    constants.GET_GEMSTONE_ENQUIRY_REQUEST,
    constants.GET_GEMSTONE_ENQUIRY_SUCCESS,
    constants.GET_GEMSTONE_ENQUIRY_FAILURE,
);

export const {
    getSingleGemstoneRequest,
    getSingleGemstoneSuccess,
    getSingleGemstoneFailure,
} = createActions(
    constants.GET_SINGLE_GEMSTONE_REQUEST,
    constants.GET_SINGLE_GEMSTONE_SUCCESS,
    constants.GET_SINGLE_GEMSTONE_FAILURE,
);

export const {
    resetGemstonesData,
} = createActions(
    constants.RESET_GEMSTONES_DATA,
);

export const {
    setSearchTerm,
} = createActions(
    constants.SET_SEARCH_TERM,
);

export const {
    setCategoryId,
} = createActions(
    constants.SET_CATEGORY_ID,
);

export const {
    setSingleGemstone,
} = createActions(
    constants.SET_SINGLE_GEMSTONE,
);

export const {
    resetCategoryId,
} = createActions(
    constants.RESET_CATEGORY_ID,
);

export const {
    setVisitFromApp,
} = createActions(
    constants.SET_VISIT_FROM_APP,
);
