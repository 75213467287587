/* eslint-disable quote-props */
import { put, call, takeEvery } from 'redux-saga/effects';

import { fetchApi } from '../utils/api';

import {
    getLanguagesSuccess,
    getLanguagesFailure,
} from './languageActions';

const {
    GET_LANGUAGES_REQUEST,
} = require('./languageActions').constants;

function* getLanguages() {

    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: '/language/list',
        });

        yield put(getLanguagesSuccess(response));
    } catch (e) {
        yield put(getLanguagesFailure(e.response ? e.response.data.message : e));
    }
}

export default function* authSaga() {
    yield* [
        takeEvery(GET_LANGUAGES_REQUEST, getLanguages),
    ];
}
