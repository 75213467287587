import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { isEmpty } from 'underscore';
import Main from '../../components/layout/Main';
import './gemstone.scss';
import useLanguage from '../../core/language/useLanguage';
import YellowThemeButton from '../../components/YellowThemeButton';
import playVideoIcon from '../../assets/images/play-video-icon.svg';
import { getTranslation, getSelectedTranslation } from '../../core/utils/helpers';
import { getSingleGemstoneRequest } from '../../core/gemstones/gemstonesActions';

const Gemstone = () => {

    const [videoWidth, setVideoWidth] = useState(0);
    const [showVideo, setShowVideo] = useState(false);
    const gemstonesData = useSelector(state => state.gemstones);
    const { singleGemstone, isFetching } = gemstonesData;

    const history = useHistory();
    const { language } = useLanguage();
    const { slug } = useParams();
    const dispatch = useDispatch();

    const { selectedLanguage } = language;

    const translation = getSelectedTranslation(singleGemstone?.translations, selectedLanguage?.id);
    const videoThumbnailUrl = `https://i.ytimg.com/vi/${translation?.video_link}/mqdefault.jpg`;

    const videoUrl = translation?.skip_branding === 1 ? `https://www.youtube.com/embed/${translation?.video_link}?start=14&autoplay=1` : `https://www.youtube.com/embed/${translation?.video_link}`;

    useEffect(() => {
        if (!singleGemstone || singleGemstone?.slug !== slug) {
            dispatch(getSingleGemstoneRequest(slug));
        }
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const updateVideoWidth = () => {
            const container = document.getElementById('video-block');
            if (container) {
                setVideoWidth(container.clientWidth);
            }
        };
        updateVideoWidth();
        window.addEventListener('resize', updateVideoWidth);
        return () => {
            window.removeEventListener('resize', updateVideoWidth);
        };
    }, [videoWidth, showVideo]);

    const handleEnquireNow = () => {
        history.push('/gemstone-enquiry');
    };

    const getContent = () => {
        if (isEmpty(singleGemstone)) {
            return (
                <div className="no-data">
                    <h1 className="title">No Gemstone Found</h1>
                    <YellowThemeButton onClick={() => history.push('/gemstones')} style={{ width: '350px', maxWidth: '90%' }}>{getTranslation(language, 'back_to_home', 'Back to Home')}</YellowThemeButton>
                </div>
            );
        }
        return (
            <div className="gemstone-container">
                <h1 className="title">{translation?.title || ''}</h1>
                <div className="content-wrapper">
                    {singleGemstone?.mediaUrl && (
                        <div className="media-wrapper">
                            {showVideo ? (
                                <div className="video-block" id="video-block">
                                    <iframe
                                        width="100%"
                                        height={videoWidth * 0.5625}
                                        src={videoUrl}
                                        title="Aries 2023 Saturn&#39;s Effect - Premium"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen />
                                </div>
                            ) : <img src={singleGemstone?.mediaUrl} alt="gemstone" />}
                            <div className="thumbnails-wrapper">
                                <div onClick={() => setShowVideo(false)}>
                                    <img src={singleGemstone?.mediaUrl} alt="thumbnail" />
                                </div>
                                {translation?.video_link && (
                                    <div className="video-thumb-wrapper" onClick={() => setShowVideo(true)}>
                                        <img src={videoThumbnailUrl} className="video-thumbnail" alt="video-thumbnail" />
                                        <img src={playVideoIcon} className="play-video-icon" alt="play-video-icon" />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <p className="gemstone-description">{translation?.description || ''}</p>
                    <YellowThemeButton onClick={handleEnquireNow} style={{ width: '350px', maxWidth: '90%' }}>{getTranslation(language, 'enquire_now', 'Enquire Now')}</YellowThemeButton>
                </div>
            </div>
        );
    };

    return (
        <Main
            className="purchase-life-events center-align"
            title={<div>{singleGemstone?.title || ''}</div>}
            headerTitle={(
                <div>
                    <img style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            showHeader
            showFooter={false}
            backLink={(
                <div style={{ display: 'flex' }}>
                    <div onClick={() => history.push('/gemstones')} className="backlink" style={{ marginBottom: '24px' }} />
                </div>
            )}>
            {isFetching ? (
                <div className="loaderSpacer">
                    <Spin size="large" />
                </div>
            ) : getContent()}
        </Main>
    );
};

export default Gemstone;
