import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, Modal, Row, Col } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { isNull } from 'underscore';
import { getTranslation } from '../../../core/utils/helpers';
import useLanguage from '../../../core/language/useLanguage';
import * as userActions from '../../../core/user/userActions';
import useUser from '../../../core/user/useUser';
import './loginForm.scss';

const LoginForm = () => {
    const dispatch = useDispatch();
    const { language } = useLanguage();
    const location = useLocation();

    // Get the URL search params from the location object
    const searchParams = new URLSearchParams(location.search);
    // Extract the value of the 'redirect' parameter
    const redirectParam = searchParams.get('redirect');

    const [forgetPasswordModalVisible, setForgetPasswordModalVisible] = useState(false);

    const { user } = useUser();

    const handleSubmit = (values) => {

        dispatch(userActions.loginRequest({
            username: values.email,
            password: values.password,
            redirectParam,
        }));
    };

    const handleForgotSubmit = (values) => {
        console.log('forgotten password values', values);
    };

    return (
        <div>
            <div className="loginform-wrap">
                <div className="loginform-container">
                    <div style={{ marginBottom: '24px' }}>
                        <img className="logo-image"
                            src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                            alt="logo" />
                    </div>
                    <Form onFinish={handleSubmit} layout="vertical">
                        <div className="form-inner-wrapper">
                            <h1 style={{ marginBottom: '24px' }}>{`${getTranslation(language, 'login_screen_main_title', 'Sign In')}`}</h1>
                            <Row>
                                <Col xs={24}>
                                    <Form.Item label={`${getTranslation(language, 'form_label_email', 'Email')}`} name="email" rules={[{ type: 'email', message: 'The input is not a valid email!' }, { required: true, message: 'Please input your email.' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label={`${getTranslation(language, 'form_label_password', 'Password')}`} name="password" rules={[{ required: true, message: 'Please input your password.' }]} className="password-input-row">
                                        <Input type="password" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} className="text-rigt">
                                    <Link to="/forgot-password"> {`${getTranslation(language, 'login_screen_forgot_password_link_text', 'Forgot password')}`} </Link>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col xs={24}>
                                {user.isFetching ? (
                                    <Button type="primary" htmlType="submit" className="ant-btn-yellow full" loading>
                                        {`${getTranslation(language, 'login_screen_main_title', 'Sign In')}`}
                                    </Button>
                                ) : (
                                    <Button type="primary" htmlType="submit" className="ant-btn-yellow full">
                                        {`${getTranslation(language, 'login_screen_main_title', 'Sign In')}`}
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Form>
                    <Row className="signup-link-row">
                        <Col xs={24}>
                            <Button type="primary"
                                href={redirectParam === 'undefined' || isNull(redirectParam) || redirectParam === ''
                                    ? '/signup'
                                    : `/signup?redirect=${redirectParam}`}
                                className="ant-btn-green full">
                                {`${getTranslation(language, 'login_screen_signup_link_text', 'New to Prakash Astrologer?')}`}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal centered width={540} footer={null} visible={forgetPasswordModalVisible} onOk={() => { setForgetPasswordModalVisible(false); }} onCancel={() => { setForgetPasswordModalVisible(false); }}>
                <h4 className="h-3 text-center">{`${getTranslation(language, 'form_forgot_password_header_label_title', 'Forgot password')}`}</h4>
                <div className="form-wrap" style={{ backgroundColor: 'black' }}>
                    <Form onFinish={handleForgotSubmit} layout="vertical">
                        <Form.Item label={`${getTranslation(language, 'form_email_label_title', 'Email')}`} name="email" rules={[{ type: 'email', message: 'The input is not valid E-mail!' }, { required: true, message: 'Please input your E-mail!' }]}>
                            <Input />
                        </Form.Item>
                        <Button type="primary" htmlType="submit" className="wide">
                            {`${getTranslation(language, 'reset_password_button_title', 'Reset password')}`}
                        </Button>
                        <div>
                            <Button className="link-green btn-empty underlined" onClick={() => { setForgetPasswordModalVisible(false); }}>
                                {`${getTranslation(language, 'cancel_button_title', 'cancel')}`}
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal>
        </div>
    );
};

export default LoginForm;
