import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    getLanguagesRequest,
    selectLanguageRequest,
} from './languageActions';

function useLanguage() {
    const dispatch = useDispatch();
    const language = useSelector(state => state.language);

    const handleGetLanguagesRequest = useCallback(() => {
        dispatch(getLanguagesRequest());
    }, []);

    const handleSelectLanguageRequest = useCallback((payload) => {
        dispatch(selectLanguageRequest(payload));
    }, []);

    return {
        language,
        getLanguagesRequest: handleGetLanguagesRequest,
        selectLanguageRequest: handleSelectLanguageRequest,
    };
}

export default useLanguage;
