import React from 'react';
import { Form, Select } from 'antd';

import useLanguage from '../../core/language/useLanguage';

import './languageSelect.scss';

const Menu = () => {

    const { language, selectLanguageRequest } = useLanguage();

    return (
        <Form
            initialValues={{
                language: language?.selectedLanguage?.id,
            }}
            className="languageSelectForm">
            <Form.Item
                name="language"
                rules={[
                    {
                        required: true,
                        message: 'Please select language!',
                    },
                ]}>
                <Select placeholder="Language" onChange={(languageId) => selectLanguageRequest(languageId)}>
                    {language.languages.map(item => (
                        <Select.Option key={`language-option-${item.id}`} value={item.id}>{item.name}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Form>
    );
};

Menu.propTypes = {
};

Menu.defaultProps = {
};

export default Menu;
