import React, { useEffect } from 'react';
import './lifeEvents.scss';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addLifeEventRequest } from '../../core/payment/paymentActions';
import Main from '../../components/layout/Main';
import useLanguage from '../../core/language/useLanguage';
import { getHoroscopeDataRange, getSelectedTranslation, getTranslation } from '../../core/utils/helpers';
import YellowThemeButton from '../../components/YellowThemeButton';
import addLifeEventIcon from '../../assets/images/add-life-event-button.svg';
import removeLifeEventIcon from '../../assets/images/remove-life-event-button.svg';
import { selectSingleLifeEventRequest } from '../../core/horoscope/horoscopeActions';

const LifeEvents = () => {

    const horoscope = useSelector(state => state.horoscope);
    const payment = useSelector(state => state.payment);

    const { singleHoroscope } = horoscope;
    const { lifeEvents } = payment;
    const { language } = useLanguage();

    const dispatch = useDispatch();
    const history = useHistory();

    const translation = getSelectedTranslation(singleHoroscope?.translations, language?.selectedLanguage?.id);

    // horoscope parts have to come from the user active horoscopes for latest purchase changes
    // If this extends to weekly and monthly it may be easier to reset the single horoscope on the
    // confirmation screen or on payment completion and take the parts from there.

    const selectedHoroscope = horoscope?.userActiveHoroscopes?.Yearly?.find((horoscopeObj) => horoscopeObj?.horoscope?.id === singleHoroscope?.id);
    const horoscopeParts = selectedHoroscope?.horoscope?.horoscopeData?.parts;
    const sortedParts = [...horoscopeParts].sort((a, b) => {
        if (a.user_has_access && !b.user_has_access) {
            return -1; // a comes before b
        }
        if (!a.user_has_access && b.user_has_access) {
            return 1; // b comes before a
        }
        return 0; // no change in order
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        // check to see if basket requires emptying
        if (lifeEvents?.length > 0) {
            // if item in the basket is already owned, reset basket
            for (let i = 0; i < lifeEvents.length; i++) {
                const eventId = lifeEvents[i];
                const foundItem = horoscopeParts.find(part => part.id === eventId);
                if (foundItem && foundItem.user_has_access) {
                    dispatch(addLifeEventRequest('reset'));
                    break;
                }
            }
            // empty basket if items are from another horoscope
            const foundFirstBasketItem = horoscopeParts.find((part) => part.id === lifeEvents[0]);
            if (!foundFirstBasketItem) {
                dispatch(addLifeEventRequest('reset'));
            }
        }
    }, []);

    const handleBasketClick = (part) => {
        dispatch(addLifeEventRequest(part?.id));
    };

    const getBasketTotal = () => {
        const basketTotal = (horoscopeParts ?? [])
            .filter(part => lifeEvents?.includes(part?.id))
            .reduce((accumulator, part) => accumulator + (part?.current_price ?? 0), 0);
        return basketTotal.toFixed(2);
    };

    const handleBuyNow = () => {
        history.push({ pathname: '/enter-payment-details', state: { type: 'lifeEvent' } });
    };

    const handleViewClick = (part) => {
        dispatch(selectSingleLifeEventRequest(part));
        history.push('/life-event-view');
    };

    const handleSignUpClick = () => {
        history.push({ pathname: '/horoscope-generated', state: { hideModal: true } });
    };

    const getPaddingBottom = () => {
        if (lifeEvents?.length > 0 && window.innerWidth < 576) {
            return '280px';
        }
        return '0px';
    };

    const renderLifeEventItem = (part) => {
        const partTranslation = getSelectedTranslation(part?.translations, language?.selectedLanguage?.id);
        const isInBasket = lifeEvents?.find((id) => id === part?.id);
        const userHasAccess = part?.user_has_access;
        let liClassName = '';

        if (userHasAccess) {
            liClassName = 'activated-part';
        } else if (isInBasket) {
            liClassName = 'selected-part';
        }

        return (
            <li key={part.id} className={liClassName}>
                {!userHasAccess && (
                    <div className="svg-wrapper" onClick={() => handleBasketClick(part)}>
                        <img src={isInBasket ? removeLifeEventIcon : addLifeEventIcon} alt="add-life-event" />
                    </div>
                )}
                <div className="list-item-content">
                    <div className="part-title">
                        <h3>{partTranslation?.name}</h3>
                        {!userHasAccess && <span>£{part?.current_price}</span>}
                    </div>
                    <p>{partTranslation?.short_description || ''}</p>
                    {userHasAccess && (
                        <div className="view-event-btn">
                            <YellowThemeButton onClick={() => handleViewClick(part)}>{getTranslation(language, 'view', 'View')}</YellowThemeButton>
                        </div>
                    )}
                </div>
            </li>
        );
    };

    const renderBasketItem = (partId) => {
        const basketPart = horoscopeParts?.find((part) => part?.id === partId);
        const partTranslation = getSelectedTranslation(basketPart?.translations, language?.selectedLanguage?.id);
        return (<li key={partId} className="basket-item">{partTranslation?.name}</li>);
    };

    return (
        <>
            <Main
                className="center-align"
                title={<div>Life Events</div>}
                headerTitle={(
                    <div>
                        <img style={{ height: 70, width: 70 }}
                            src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                            alt="logo" />
                    </div>
                )}
                menuWidth={350}
                menuPlacement="right"
                showHeader
                displayRightMenu
                showFooter={false}
                backLink={(
                    <div style={{ display: 'flex' }}>
                        <Link to="/dashboard?tab=3" className="backlink" style={{ marginBottom: '24px' }} />
                    </div>
                )}>
                <div className="purchase-life-events">
                    <h1>{getTranslation(language, 'purchase_life_events', 'Purchase Life Events')} - {translation?.name} {getHoroscopeDataRange(singleHoroscope)}</h1>
                    <p className="life-events-description">{getTranslation(language, 'life_events_description', 'The premium annual horoscope offers an in-depth exploration of the upcoming year through 13 meticulously detailed sections.')}</p>
                </div>
            </Main>
            <div className="content-container" style={{ paddingBottom: getPaddingBottom() }}>
                <div className="content-grid">
                    <div className="parts-list">
                        {!singleHoroscope?.is_user_subscribed && (<YellowThemeButton onClick={handleSignUpClick} style={{ maxWidth: '350px' }}>{getTranslation(language, 'sign_up_to_diamond_package', 'Sing Up to The Diamond Package')}</YellowThemeButton>)}
                        <ul>
                            {sortedParts.map((part) => renderLifeEventItem(part))}
                        </ul>
                    </div>
                    <div className="basket">
                        <h3>{getTranslation(language, 'basket', 'Basket')}</h3>
                        <div className="basket-info-wrapper">
                            <p>{getTranslation(language, 'quantity', 'Quantity')}</p>
                            <p>{lifeEvents?.length}</p>
                        </div>
                        {lifeEvents?.length > 0
                            ? <ul>{lifeEvents.map((id) => renderBasketItem(id))}</ul>
                            : null}
                        <div className="basket-info-wrapper" style={{ marginBottom: '12px' }}>
                            <p>{getTranslation(language, 'total_cost', 'Total Cost')}</p>
                            <p>£{getBasketTotal()}</p>
                        </div>
                        <YellowThemeButton onClick={handleBuyNow} disabled={!lifeEvents.length}>{getTranslation(language, 'buy_now', 'Buy Now')}</YellowThemeButton>
                    </div>
                </div>
            </div>

            <div className="mobile-basket" style={lifeEvents?.length > 0 ? { maxHeight: '500px', bottom: '0px' } : { maxHeight: '0px', bottom: '-50px' }}>
                <h3>{getTranslation(language, 'basket', 'Basket')}</h3>
                <div className="basket-info-wrapper">
                    <p>{getTranslation(language, 'quantity', 'Quantity')}</p>
                    <p>{lifeEvents?.length}</p>
                </div>
                <div className="basket-info-wrapper" style={{ marginBottom: '12px' }}>
                    <p>{getTranslation(language, 'total_cost', 'Total Cost')}</p>
                    <p>£{getBasketTotal()}</p>
                </div>
                <YellowThemeButton onClick={handleBuyNow} disabled={!lifeEvents.length}>{getTranslation(language, 'buy_now', 'Buy Now')}</YellowThemeButton>
            </div>

        </>
    );
};

export default LifeEvents;
