import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    horoscopeListRequest,
    findUserHoroscopeRequest,
    selectSingleHoroscopeRequest,
    userActiveHoroscopesRequest,
    selectHoroscopePlanRequest,
} from './horoscopeActions';

function useHoroscope() {

    const dispatch = useDispatch();

    const horoscope = useSelector(state => state.horoscope);

    const { singleHoroscope } = horoscope;

    const handleGetHoroscopeListRequest = useCallback(() => {
        dispatch(horoscopeListRequest());
    }, []);

    // we have depriciated this instead we using dispatch directly on page
    const handleGetUserActiveHoroscopes = useCallback(() => {
        dispatch(userActiveHoroscopesRequest());
    }, []);

    const handleFindUserHoroscopeRequest = useCallback(() => {
        dispatch(findUserHoroscopeRequest());
    }, []);

    const handleSelectSingleHoroscopeRequest = useCallback((payload) => {
        dispatch(selectSingleHoroscopeRequest(payload));
    }, []);

    const handleSelectHoroscopePlanRequest = useCallback((payload) => {
        dispatch(selectHoroscopePlanRequest(payload));
    }, []);

    return {
        horoscope,
        singleHoroscope,
        horoscopeListRequest: handleGetHoroscopeListRequest,
        findUserHoroscopeRequest: handleFindUserHoroscopeRequest,
        selectSingleHoroscopeRequest: handleSelectSingleHoroscopeRequest,
        getUserActiveHoroscopes: handleGetUserActiveHoroscopes,
        selectHoroscopePlanRequest: handleSelectHoroscopePlanRequest,
    };
}

export default useHoroscope;
