import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Main from '../../components/layout/Main';
import useUser from '../../core/user/useUser';
import './gemstoneEnquiry.scss';
import { getGemstoneEnquiryRequest } from '../../core/gemstones/gemstonesActions';
import { getTranslation } from '../../core/utils/helpers';
import useLanguage from '../../core/language/useLanguage';

const GemstoneEnquiry = () => {
    const history = useHistory();
    const { user } = useUser();
    const dispatch = useDispatch();
    const { language } = useLanguage();

    const gemstonesData = useSelector(state => state.gemstones);
    const { singleGemstone } = gemstonesData;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!user) {
            history.push('/');
        }
    }, [user]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        const payload = {
            ...data,
            gemstoneId: singleGemstone?.id,
            userId: user?.userData?.id,
        };
        dispatch(getGemstoneEnquiryRequest(payload));
        history.push('/gemstone-enquiry-success');
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return (
        <Main
            className="purchase-life-events center-align"
            title={<div>Gemstone Enquiry</div>}
            headerTitle={(
                <div>
                    <img style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            showHeader
            showFooter={false}
            backLink={(
                <div style={{ display: 'flex' }}>
                    <div onClick={() => history.goBack()} className="backlink" style={{ marginBottom: '24px' }} />
                </div>
            )}>
            <div className="gemstone-enquiry-container">
                <h1>{getTranslation(language, 'submit_an_enquiry', 'Submit an Enquiry')}</h1>
                <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row">
                        <label>{getTranslation(language, 'full_name', 'Full Name')}</label>
                        <input
                            {...register('fullName', { required: true, maxLength: 50 })}
                            defaultValue={user?.userData?.full_name} />
                        {errors.fullName && <span className="error">{getTranslation(language, 'please_enter_required_information', 'Please enter the required information')}</span>}
                        {errors.fullName?.type === 'maxLength' && (
                            <span className="error">{getTranslation(language, 'maximum_characters_allowed', 'Maximum 50 characters allowed')}</span>
                        )}
                    </div>
                    <div className="form-row">
                        <label>{getTranslation(language, 'email', 'Email')}</label>
                        <input
                            {...register('email', { required: true,
                                maxLength: 50,
                                pattern: {
                                    value: emailRegex,
                                } })}
                            defaultValue={user?.userData?.email} />
                        {errors.email?.type === 'required' && <span className="error">{getTranslation(language, 'please_enter_required_information', 'Please enter the required information')}</span>}
                        {errors.email?.type === 'maxLength' && (
                            <span className="error">{getTranslation(language, 'maximum_characters_allowed', 'Maximum 50 characters allowed')}</span>
                        )}
                        {errors.email?.type === 'pattern' && (
                            <span className="error">{getTranslation(language, 'please_check_email', 'Please check the email format')}</span>
                        )}
                    </div>
                    <div className="form-row">
                        <label>{getTranslation(language, 'phone', 'Phone')}</label>
                        <input
                            {...register('phone', { required: true, maxLength: 20 })} />
                        {errors.phone && <span className="error">{getTranslation(language, 'please_enter_required_information', 'Please enter the required information')}</span>}
                        {errors.phone?.type === 'maxLength' && (
                            <span className="error">{getTranslation(language, 'maximum_20_characters_allowed', 'Maximum 20 characters allowed')}</span>
                        )}
                    </div>
                    <div className="form-row">
                        <label style={{ marginBottom: '10px' }}>{getTranslation(language, 'question', 'Question')}</label>
                        <textarea
                            {...register('question', { maxLength: 500 })} />
                        {errors.question?.type === 'maxLength' && (
                            <span className="error">{getTranslation(language, 'maximum_500_characters_allowed', 'Maximum 500 characters allowed')}</span>
                        )}
                    </div>
                    <div className="caption-content">
                        <span className="caption-question">{getTranslation(language, 'enquiry_question', 'Have a question? Call us, we\'d love to help')}</span>
                        <a href="tel:+443332420863" className="caption-tel">{getTranslation(language, 'tel', 'Tel')}: +44 333 242 0863</a>
                    </div>

                    <div>
                        <input className="submit-button" type="submit" value={getTranslation(language, 'submit', 'Submit')} />
                    </div>
                </form>
            </div>
        </Main>
    );
};

export default GemstoneEnquiry;
