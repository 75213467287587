import React from 'react';
import Helmet from 'react-helmet';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation } from '../../core/utils/helpers';
import './notFound.scss';

const NotFound = () => {
    const { language } = useLanguage();
    return (
        <div className="ui container not-found">
            <Helmet title="Not found" />
            <div className="ui icon message">
                <i className="warning sign icon" />
                <div className="content">
                    <div className="header">
                        {getTranslation(language, 'navigation_page_not_found_title', 'Page not found')}
                    </div>
                    <p>
                        {getTranslation(language, 'navigation_page_not_found_description', 'The page you are looking for does not exist.')}

                    </p>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
