/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Drawer, Button } from 'antd';
import { useSelector } from 'react-redux';
import { getTranslation, getSelectedTranslation } from '../../../core/utils/helpers';
import useLanguage from '../../../core/language/useLanguage';

import defaultLogo from '../../../assets/images/logo.png';

import './rightMenuConsultations.scss';

const RightMenuConsultations = (props) => {

    const [menuOpen, setMenuOpen] = useState(false);
    const { scrollTo } = props;

    const { language } = useLanguage();
    const consultations = useSelector(state => state.consultation);
    const user = useSelector(state => state.user);

    const openDrawer = () => {
        setMenuOpen(true);
    };

    const closeDrawer = () => {
        setMenuOpen(false);
    };

    const groupByCategory = (items) => {
        let grouped = items.reduce((r, a) => {
            const categoryTranslation = getSelectedTranslation(a?.category?.translations, language?.selectedLanguage?.id);
            if (!r[a.category.id]) {
                r[a.category.id] = {};
            }
            r[a.category.id].data = r[a.category.id].data || [];
            r[a.category.id].data.push(a);
            r[a.category.id].title = categoryTranslation?.name;

            return r;
        }, Object.create(null));
        grouped = Object.values(grouped);
        return grouped;
    };

    const groupedConsultations = groupByCategory(consultations.allConsultations);

    return (
        <div className="menu">
            <Button className="right-menu-toggle-button" onClick={openDrawer}>
                <div className="right-menu-toggle-icon" />
            </Button>
            <Drawer
                visible={menuOpen}
                onClose={closeDrawer}
                placement="right"
                width="350"
                className="right-menu-drawer"
                closable={false}>
                <div className="menu__header">
                    <Button className="right-menu-toggle-button" onClick={closeDrawer}>
                        <div className="right-menu-toggle-icon" />
                    </Button>
                </div>
                <div className="logo-block">
                    <h4 className="logo-title">{getTranslation(language, 'consultations_right_menu_title', 'Categories')}</h4>
                </div>
                <ul className="capter-menu">
                    {groupedConsultations.map((g, index) => (
                        <li key={`side-menu-${index}`}>
                            <button type="button" onClick={() => { scrollTo(index); closeDrawer(); }}>
                                {g.title}
                            </button>
                        </li>
                    ))}
                </ul>

                <div className="menu__footer">
                    <div className="bottom-sidebar-link">
                        {user.userData?.id !== undefined
                            ? (
                                <p>
                                    <Link to="/my-subscriptions">
                                        {`${getTranslation(language, 'subscription_settings_screen_title', 'Subscriptions')}`}
                                    </Link>
                                </p>
                            )
                            : null}
                        <p>
                            <a href="https://www.prakashastrologer.com/disclaimer/" target="_blank" rel="noreferrer">
                                {`${getTranslation(language, 'settings_screen_astrologer_disclaimer_link_title', 'Astrologer Disclaimer')}`}

                            </a>
                        </p>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

RightMenuConsultations.propTypes = {
    items: PropTypes.array,
    logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    placement: PropTypes.string,
    user: PropTypes.object,
    width: PropTypes.number,
    scrollTo: () => {},
};

RightMenuConsultations.defaultProps = {
    items: [],
    logo: defaultLogo,
    placement: 'right',
    width: 256,
    user: {},
    scrollTo: PropTypes.func,
};

export default RightMenuConsultations;
