import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'underscore';
import { Spin } from 'antd';
import Main from '../../components/layout/Main';
import searchIcon from '../../assets/images/search-icon.svg';
import { getGemstonesRequest, resetGemstonesData, setSearchTerm, resetCategoryId, setVisitFromApp } from '../../core/gemstones/gemstonesActions';
import './gemstones.scss';
import GemstoneCard from '../../components/GemstoneCard';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation } from '../../core/utils/helpers';

const Gemstones = () => {
    const [firstLoad, setFirstLoad] = useState(true);
    const gemstonesData = useSelector(state => state.gemstones);
    const { gemstones = [], page, lastPage, lastUpdated, searchTerm, totalGemstones, visitFromApp, categoryId } = gemstonesData;

    const dispatch = useDispatch();
    const { language } = useLanguage();

    useEffect(() => {
        if (visitFromApp) {
            dispatch(setVisitFromApp(false));
            dispatch(resetCategoryId());
        }
        window.scrollTo(0, 0);
        setFirstLoad(false);
    }, []);

    useEffect(() => {
        if (gemstones && !gemstones.length) {
            dispatch(getGemstonesRequest());
        }
        const lastUpdatedDate = new Date(lastUpdated);
        const fourHoursAgo = new Date();
        fourHoursAgo.setHours(fourHoursAgo.getHours() - 4);
        if (lastUpdated && lastUpdatedDate < fourHoursAgo) {
            dispatch(resetGemstonesData());
        }
    }, [dispatch, gemstones]);

    useEffect(() => {
        if (!firstLoad) {
            dispatch(resetGemstonesData());
        }
    }, [categoryId]);

    const handleSearchChange = (e) => {
        const { value } = e.target;
        dispatch(setSearchTerm(value));
    };

    const handleSearch = () => {
        dispatch(resetGemstonesData());
    };

    const debouncedSearch = debounce(handleSearch, 1500);

    useEffect(() => {
        if (!firstLoad) {
            debouncedSearch();
        }
        return debouncedSearch.cancel; // cleanup the debounce on unmount
    }, [searchTerm]);

    const fetchData = () => {
        dispatch(getGemstonesRequest());
    };

    return (
        <>
            <Main
                title={<div>Gemstone Jewellery</div>}
                headerTitle={(
                    <div>
                        <img style={{ height: 70, width: 70 }}
                            src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                            alt="logo" />
                    </div>
                )}
                menuWidth={350}
                menuPlacement="right"
                displayRightMenu
                showFooter={false} />
            <div className="container">
                <h1>{getTranslation(language, 'gemstone_jewellery', 'Gemstone Jewellery')}</h1>
                <div className="input-wrapper">
                    <img src={searchIcon} alt="add-life-event" />
                    <input type="text" placeholder={getTranslation(language, 'search', 'Search')} onChange={handleSearchChange} value={searchTerm} />
                </div>
                <p>{totalGemstones} {getTranslation(language, 'results', 'Results')}</p>
                <InfiniteScroll
                    dataLength={gemstones.length}
                    next={fetchData}
                    hasMore={page !== lastPage}
                    loader={(
                        <p>
                            <Spin size="large" />
                        </p>
                    )}>
                    <div className="gemstone-display">
                        {gemstones.map((gemstone) => (
                            <GemstoneCard key={gemstone.id} gemstone={gemstone} language={language} />
                        ))}
                    </div>
                </InfiniteScroll>
            </div>
        </>
    );
};

export default Gemstones;
