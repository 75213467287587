import React from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useLanguage from '../../../core/language/useLanguage';
import * as userActions from '../../../core/user/userActions';
import useUser from '../../../core/user/useUser';
import { getTranslation } from '../../../core/utils/helpers';
import '../LoginForm/loginForm.scss';

const ChangePasswordForm = () => {
    const { language } = useLanguage();
    const { user } = useUser();
    const dispatch = useDispatch();
    const { token, email } = useParams();

    const handleSubmit = (values) => {
        dispatch(userActions.resetPasswordRequest({
            email,
            token,
            password: values.newPassword,
            password_confirmation: values.confirmPassword,
        }));
    };

    const validatePassword = (_, value) => {
        if (!value) {
            return Promise.reject(getTranslation(language, 'password_required', 'Password is required.'));
        }
        if (value.length < 8) {
            return Promise.reject(getTranslation(language, 'password_too_short', 'Password must be at least 8 characters long.'));
        }
        if (!/[a-z]/.test(value)) {
            return Promise.reject(getTranslation(language, 'password_lowercase_required', 'Password must include at least one lowercase letter.'));
        }
        if (!/[A-Z]/.test(value)) {
            return Promise.reject(getTranslation(language, 'password_uppercase_required', 'Password must include at least one uppercase letter.'));
        }
        if (!/[0-9]/.test(value)) {
            return Promise.reject(getTranslation(language, 'password_number_required', 'Password must include at least one number.'));
        }
        return Promise.resolve();
    };

    return (
        <div>
            <div className="loginform-wrap">
                <div className="loginform-container">
                    <Form onFinish={handleSubmit} layout="vertical">
                        <div className="form-inner-wrapper">
                            <h1>{getTranslation(language, 'update_password', 'Update Password')}</h1>
                            <Row>
                                <Col xs={24}>
                                    <Form.Item
                                        label={getTranslation(language, 'please_enter_your_new_password', 'Please enter your new password')}
                                        name="newPassword"
                                        rules={[
                                            { validator: validatePassword },
                                        ]}>
                                        <Input.Password />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24}>
                                    <Form.Item
                                        label={getTranslation(language, 'please_confirm_your_new_password', 'Please confirm your new password')}
                                        name="confirmPassword">
                                        <Input.Password />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col xs={24}>
                                {user.isFetching ? (
                                    <Button type="primary" htmlType="submit" className="ant-btn-yellow" loading>
                                        {getTranslation(language, 'forgotten_password_submit_button_text', 'Submit')}
                                    </Button>
                                ) : (
                                    <Button type="primary" htmlType="submit" className="ant-btn-yellow">
                                        {getTranslation(language, 'forgotten_password_submit_button_text', 'Submit')}
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default ChangePasswordForm;
