import React from 'react';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import Main from '../../components/layout/Main';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation } from '../../core/utils/helpers';

import './resetPassword.scss';
import ChangePasswordForm from '../../components/Forms/ChangePasswordForm';

const ResetPassword = () => {
    const { language } = useLanguage();
    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];

    return (
        <Main
            className="login"
            title={<div>Welcome</div>}
            headerTitle={<div>Login Page</div>}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            // floatingFooter
            // floatingHeader
            // breadcrumb={navigation}
            showHeader={false}
            showFooter={false}
            backLink={(
                <Button onClick={() => console.log('Back')}>
                    <LeftOutlined />  {getTranslation(language, 'navigation_title_back', 'Back')}
                </Button>
            )}>
            <ChangePasswordForm />
        </Main>
    );
};

export default ResetPassword;

ResetPassword.propTypes = {};
