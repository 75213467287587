import React, { useEffect, useState } from 'react';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation } from '../../core/utils/helpers';
import Main from '../../components/layout/Main';
import './OneOffConfirmationMobileSuccessfull.scss';

const OneOffConfirmationMobileSuccessfull = () => {
    const { language } = useLanguage();
    const [redirected, setRedirected] = useState(false);

    useEffect(() => {
        if (!redirected) {
            setTimeout(() => {
                window.location.replace('prakashastrology://oneOffConfirmation/true');
                setRedirected(true);
            }, 5000);
        }
    }, []);

    return (
        <Main
            title={<div>Profile - Prakash Astrologer</div>}
            headerTitle={(
                <div>
                    <img style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            menuWidth={350}
            menuPlacement="right"
            floatingHeader
            displayRightMenu={false}
            showFooter={false}>
            <div className="oneoffconfirmation-confirmation">

                <h2 className="c-page-heading">{getTranslation(language, 'one_off_confirmation_success_screen_title', 'Order successfull')}</h2>
                <div className="c-description-container">
                    <p className="c-description">
                        {getTranslation(language, 'one_off_confirmation_success_screen_message',
                            'Order placed successfully! You can now safely close this window and navigate to moble app')}
                    </p>
                </div>
            </div>
        </Main>

    );
};

export default OneOffConfirmationMobileSuccessfull;
