import React from 'react';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation } from '../../core/utils/helpers';
import Main from '../../components/layout/Main';
import SignupForm from '../../components/Forms/SignupForm';
import './signup.scss';

const Signup = () => {
    const { language } = useLanguage();

    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];

    return (
        <Main
            className="Signup "
            title={<div>Welcome</div>}
            headerTitle={<div>{getTranslation(language, 'sign_up_first_screen_title', 'Sign Up')}</div>}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2019</div>}
            showHeader={false}
            showFooter={false}
            backLink={(
                <Button onClick={() => console.log('Back')}>
                    <LeftOutlined /> {getTranslation(language, 'forgotten_password_back_link_text', 'Back')}
                </Button>
            )}>
            {/* <BreadcrumbNav separator=">" navigation={navigation} /> */}
            <div>
                <h1 className="title-text">{getTranslation(language, 'sign_up_second_screen_title', 'Create your account')}</h1>
            </div>
            <SignupForm />
        </Main>
    );
};

export default Signup;
