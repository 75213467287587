/* eslint-disable quote-props */
import { message } from 'antd';
import { put, call, takeEvery } from 'redux-saga/effects';
import { fetchApiAuth } from '../utils/api';

import {
    noticeboardSuccess,
    noticeboardFailure,

    noticeboardReadStatusSuccess,
    noticeboardReadStatusFailure,

} from './noticeboardActions';

const {
    NOTICEBOARD_REQUEST,
    NOTICEBOARD_READ_STATUS_REQUEST,
} = require('./noticeboardActions').constants;

function* noticeboard() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'noticeboard/user-noticeboard',
        });

        yield put(noticeboardSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(noticeboardFailure(e.response ? e.response.data.message : e));
    }
}

function* noticeboardReadStatus(payload) {
    try {
        const { filteredIds } = payload.payload;

        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/noticeboard/user-noticeboard-mark-read',
            body: filteredIds,
        });
        yield put(noticeboardSuccess(response));
        yield put(noticeboardReadStatusSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(noticeboardReadStatusFailure(e.response ? e.response.data.message : e));
    }
}

export default function* consultationsSaga() {
    yield* [
        takeEvery(NOTICEBOARD_REQUEST, noticeboard),
        takeEvery(NOTICEBOARD_READ_STATUS_REQUEST, noticeboardReadStatus),
    ];
}
