import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import usePayment from '../../core/payment/usePayment';
import useUser from '../../core/user/useUser';
import ProfileForm from '../../components/Forms/ProfileForm';
import Main from '../../components/layout/Main';
import { getTranslation } from '../../core/utils/helpers';
import useLanguage from '../../core/language/useLanguage';

import './profile.scss';

const MyProfile = () => {
    const { language } = useLanguage();

    const { getUserHistoricSubscriptionsRequest } = usePayment();
    const { getUserDetailsRequest } = useUser();

    useEffect(() => {
        // Update the document title using the browser API
        getUserHistoricSubscriptionsRequest();
        getUserDetailsRequest();
    }, []);

    return (
        <Main
            title={<div>Profile - Prakash Astrologer</div>}
            headerTitle={<div><img style={{ height: 70, width: 70 }} src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg" alt="logo" /></div>}
            backLink={<Link to="/dashboard" className="backlink">{getTranslation(language, 'horoscopes_header_title', 'Horoscopes')}</Link>}
            menuWidth={350}
            menuPlacement="right"
            floatingHeader={false}
            displayRightMenu={false}
            showFooter={false}>
            <div className="profile-form-container">
                <ProfileForm />
            </div>
        </Main>
    );
};

export default MyProfile;

MyProfile.propTypes = {};
