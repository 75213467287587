import React, { useState, useEffect } from 'react';
import { Drawer, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { getGemstoneCategoriesRequest, setCategoryId } from '../../../core/gemstones/gemstonesActions';
import { getTranslation, getSelectedTranslation } from '../../../core/utils/helpers';
import useLanguage from '../../../core/language/useLanguage';
import './gemstonesMenu.scss';

const GemstonesMenu = () => {

    const [menuOpen, setMenuOpen] = useState(false);
    const gemstonesData = useSelector(state => state.gemstones);
    const { gemstoneCategories, categoryId } = gemstonesData;

    const dispatch = useDispatch();
    const { language } = useLanguage();

    useEffect(() => {
        if (!gemstoneCategories || !gemstoneCategories.length) {
            dispatch(getGemstoneCategoriesRequest());
        }
    }, [dispatch]);

    const openDrawer = () => {
        setMenuOpen(true);
    };

    const closeDrawer = () => {
        setMenuOpen(false);
    };

    const handleCategoryClick = (newCategoryId) => {
        dispatch(setCategoryId(newCategoryId));
        closeDrawer();
    };

    const renderCategory = (category) => {
        const translation = getSelectedTranslation(category.translations, language.selectedLanguage.id);
        return (
            <li key={category.id}>
                <button type="button" onClick={() => handleCategoryClick(category.id)} style={{ fontWeight: category.id === categoryId ? 'bold' : 'normal' }}>
                    {translation?.title}
                </button>
            </li>
        );
    };

    return (
        <div className="menu">
            <Button className="right-menu-toggle-button" onClick={openDrawer}>
                <div className="right-menu-toggle-icon" />
            </Button>
            <Drawer
                visible={menuOpen}
                onClose={closeDrawer}
                placement="right"
                width="350"
                className="right-menu-drawer"
                closable={false}>
                <div className="gem-menu-header">
                    <h2 className="title">{getTranslation(language, 'categories', 'Categories')}</h2>
                    <Button className="right-menu-toggle-button" onClick={closeDrawer} style={{ paddingRight: '40px' }}>
                        <div className="right-menu-toggle-icon" />
                    </Button>
                </div>
                <div className="gem-right-menu-list-block">
                    <ul className="categories-list">
                        <li>
                            <button type="button" onClick={() => handleCategoryClick(0)} style={{ fontWeight: categoryId === 0 ? 'bold' : 'normal' }}>
                                {getTranslation(language, 'all_categories', 'All Categories')}
                            </button>
                        </li>
                        {gemstoneCategories.map((category) => renderCategory(category))}
                    </ul>
                </div>
            </Drawer>
        </div>
    );
};

export default GemstonesMenu;
