// import { useDispatch } from 'react-redux';
import React from 'react';
import { Button, Form, Input, Row, Col, message } from 'antd';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import './addPaymentCardsMobile.scss';
import usePayment from '../../../core/payment/usePayment';
import { getTranslation } from '../../../core/utils/helpers';
import useLanguage from '../../../core/language/useLanguage';

const FormItem = Form.Item;

const CheckoutForm = ({ type, consultation_id }) => {

    // const form = Form.useForm();
    const { addPaymentCardRequest, payment } = usePayment();
    const { language } = useLanguage();
    const user = useSelector(state => state.user.userData);

    const handleSubmit = (values) => {
        let validate = true;

        const date = values.card_exp_date.split('/');

        let exp_month = '';
        let exp_year = '';

        if (date[0] && date[1]) {
            [exp_month, exp_year] = date;
        } else {
            validate = false;
            message.error('Please enter valid Expire date');
        }

        if (validate) {
            addPaymentCardRequest({
                card_number: values.card_number,
                card_exp_month: exp_month,
                card_exp_year: exp_year,
                card_cvc: values.card_cvc,
                holders_name: values.holders_name,
                paymentPlanId: payment?.selectedPlan?.id,
                user_id: user?.id,
                type,
                consultation_id,
                payment_type: 'web',
            });
        }
    };

    return (
        <Form onFinish={handleSubmit} layout="vertical" className="form payment-form">
            <div className="payment-form-content-wrapper">
                <FormItem
                    name="card_number"
                    label={getTranslation(language, 'payment_card_form_card_number_label', 'Card Number:')}
                    rules={[{ max: 16, message: getTranslation(language, 'payment_card_form_card_number_valid_max', 'Field cannot be longer than 16') }, { required: true, message: getTranslation(language, 'payment_card_form_card_number_valid_required', 'Please enter Card Number!') }]}>
                    <Input maxLength={16} className="payment-input" placeholder="0000 0000 0000 0000" />
                </FormItem>
                <FormItem
                    name="holders_name"
                    label={getTranslation(language, 'payment_card_form_holders_name_label', 'Cardholder’s Name:')}
                    rules={[{ required: true, message: getTranslation(language, 'payment_card_form_holders_name_valid_required', 'Please enter Cardholder’s Name!') }]}>
                    <Input size="large" className="payment-input" placeholder="ex. Jonathan Paul Ive" />
                </FormItem>

                <Row gutter={40}>
                    <Col span={12}>
                        <FormItem
                            name="card_exp_date"
                            label={getTranslation(language, 'payment_card_form_card_exp_date_label', 'Expiry Date')}
                            rules={[
                                { required: true, message: getTranslation(language, 'payment_card_form_card_exp_date_valid_required', 'Please enter Expiry Date') },
                                { max: 7, message: getTranslation(language, 'payment_card_form_card_exp_date_valid_format', 'Field enter valid date MM/YYYY') },
                                { min: 7, message: getTranslation(language, 'payment_card_form_card_exp_date_valid_format', 'Field enter valid date MM/YYYY') }]}>
                            <Input size="large" maxLength={7} className="payment-input" placeholder="MM / YYYY" />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="card_cvc"
                            label={getTranslation(language, 'payment_card_form_card_cvc_label', 'Security Code:')}
                            rules={[{ required: true, message: getTranslation(language, 'payment_card_form_card_cvc_valid_required', 'Please enter Security Code (CSV)!') }]}>
                            <Input size="large" className="payment-input" placeholder="3-4 digits" />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <div className="text-center mt-5">
                {payment.isFetching ? (
                    <Button type="primary" htmlType="submit" className="ant-btn-yellow" loading>
                        {getTranslation(language, 'payment_card_form_pay_button_label', 'Pay')}
                    </Button>
                ) : (
                    <Button type="primary" htmlType="submit" className="ant-btn-yellow">
                        {getTranslation(language, 'payment_card_form_pay_button_label', 'Pay')}
                    </Button>
                )}
            </div>
        </Form>
    );
};

CheckoutForm.propTypes = {
    type: PropTypes.string,
    consultation_id: PropTypes.string,
};

CheckoutForm.defaultProps = {
    type: '',
    consultation_id: '',
};

export default CheckoutForm;
