import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    getPaymentPlansRequest,
    selectPlanRequest,
    addPaymentCardRequest,
    getUserHistoricSubscriptionsRequest,
    applyCouponCodeRequest,
    cancelSubscriptionRequest,
} from './paymentActions';

function useAuth() {
    const dispatch = useDispatch();
    const payment = useSelector(state => state.payment);
    const { selectedPlan } = payment;

    const handleGetPaymentPlansRequest = useCallback((payload) => {
        dispatch(getPaymentPlansRequest(payload));
    }, []);

    const handleSelectPlanRequest = useCallback((payload) => {
        dispatch(selectPlanRequest(payload));
    }, []);

    const handleAddPaymentCardRequest = useCallback((payload) => {
        dispatch(addPaymentCardRequest(payload));
    }, []);

    const handleGetUserHistoricSubscriptionsRequest = useCallback((payload) => {
        dispatch(getUserHistoricSubscriptionsRequest(payload));
    }, []);

    const handleCouponCodeRequest = useCallback((payload) => {
        dispatch(applyCouponCodeRequest(payload));
    }, []);

    const handleCancelSubscriptionRequest = useCallback((payload) => {
        dispatch(cancelSubscriptionRequest(payload));
    }, []);

    return {
        payment,
        selectedPlan,
        getPaymentPlansRequest: handleGetPaymentPlansRequest,
        selectPlanRequest: handleSelectPlanRequest,
        addPaymentCardRequest: handleAddPaymentCardRequest,
        getUserHistoricSubscriptionsRequest: handleGetUserHistoricSubscriptionsRequest,
        applyCouponCodeRequest: handleCouponCodeRequest,
        cancelSubscriptionRequest: handleCancelSubscriptionRequest,
    };
}

export default useAuth;
