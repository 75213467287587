import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    NOTICEBOARD_REQUEST: null,
    NOTICEBOARD_SUCCESS: null,
    NOTICEBOARD_FAILURE: null,

    NOTICEBOARD_READ_STATUS_REQUEST: null,
    NOTICEBOARD_READ_STATUS_SUCCESS: null,
    NOTICEBOARD_READ_STATUS_FAILURE: null,

});

export const {
    noticeboardRequest,
    noticeboardSuccess,
    noticeboardFailure,
} = createActions(
    constants.NOTICEBOARD_REQUEST,
    constants.NOTICEBOARD_SUCCESS,
    constants.NOTICEBOARD_FAILURE,
);

export const {
    noticeboardReadStatusRequest,
    noticeboardReadStatusSuccess,
    noticeboardReadStatusFailure,
} = createActions(
    constants.NOTICEBOARD_READ_STATUS_REQUEST,
    constants.NOTICEBOARD_READ_STATUS_SUCCESS,
    constants.NOTICEBOARD_READ_STATUS_FAILURE,
);
