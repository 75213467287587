import { call, put, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth } from '../utils/api';

import {
    horoscopeListSuccess,
    horoscopeListFailure,

    findUserHoroscopeSuccess,
    findUserHoroscopeFailure,

    userActiveHoroscopesSuccess,
    userActiveHoroscopesFailure,

    checkIfLiveSuccess,
    checkIfLiveFailure,
} from './horoscopeActions';

const {
    HOROSCOPE_LIST_REQUEST,
    FIND_USER_HOROSCOPE_REQUEST,
    USER_ACTIVE_HOROSCOPES_REQUEST,
    CHECK_IF_LIVE_REQUEST,
} = require('./horoscopeActions').constants;

function* horoscopeList() {

    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/horoscope',
        });

        yield put(horoscopeListSuccess(response));
    } catch (e) {
        console.log('error', e);
        // message.error(e.response ? e.response.data.message : e);
        yield put(horoscopeListFailure(e.response ? e.response.data.message : e));
    }
}

function* findUserHoroscope() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/horoscope/find-user-horoscope',
        });

        yield put(findUserHoroscopeSuccess(response));
    } catch (e) {
        message.error('Error', e.response?.data?.message || 'Unable to find horoscope');
        yield put(findUserHoroscopeFailure(e.response ? e.response.data.message : e));
    }
}

function* userActiveHoroscopes(action) {
    const { refresh } = action.payload || false;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `horoscope/user-horoscopes?refresh=${refresh ? '1' : '0'}`,
        });
        yield put(userActiveHoroscopesSuccess(response.data));
    } catch (e) {
        // showError('Error', e.response ? e.response.data.message : e);
        yield put(userActiveHoroscopesFailure(e.response ? e.response.data.message : e));
    }
}

function* checkIfLive() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'horoscope/live-streaming-status',
        });
        yield put(checkIfLiveSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(checkIfLiveFailure(e.response ? e.response.data.message : e));
    }
}

export default function* authSaga() {
    yield* [
        takeEvery(HOROSCOPE_LIST_REQUEST, horoscopeList),
        takeEvery(FIND_USER_HOROSCOPE_REQUEST, findUserHoroscope),
        takeEvery(USER_ACTIVE_HOROSCOPES_REQUEST, userActiveHoroscopes),
        takeEvery(CHECK_IF_LIVE_REQUEST, checkIfLive),
    ];
}
