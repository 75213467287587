import React from 'react';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import Main from '../../components/layout/Main';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation } from '../../core/utils/helpers';
import './horoscope.scss';

const OrderComplete = () => {

    const language = useLanguage();
    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];

    return (
        <Main
            className="login center-align"
            title={<div>Welcome</div>}
            headerTitle={<div>Horoscope Generated</div>}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2019</div>}
            showHeader={false}
            showFooter={false}
            backLink={(
                <Button onClick={() => console.log('Back')}>
                    <LeftOutlined /> {getTranslation(language, 'navigation_title_back', 'Back')}
                </Button>
            )}>
            <div>
                <h1 className="title-text">{getTranslation(language, 'order_complete_screen_title', 'Order Complete')} </h1>
                <div>
                    <p className="description">
                        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                        Velit officia consequat duis enim velit mollit.
                        Exercitation veniam consequat sunt nostrud amet.
                    </p>
                </div>
                <Button className="ant-btn-yellow" htmlType="submit" type="primary">
                    {getTranslation(language, 'order_complete_choose_moonsign_button_title', 'Choose your Moonsign')}
                </Button>
            </div>
        </Main>
    );
};

export default OrderComplete;
