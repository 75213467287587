import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_CONSULTATIONS_REQUEST: null,
    GET_CONSULTATIONS_SUCCESS: null,
    GET_CONSULTATIONS_FAILURE: null,

    SELECT_SINGLE_CONSULTATION_REQUEST: null,

    SAVE_CONSULTATION_REQUEST: null,
    SAVE_CONSULTATION_SUCCESS: null,
    SAVE_CONSULTATION_FAILURE: null,

    GET_SELECTED_CONSULTATION_REQUEST: null,
    GET_SELECTED_CONSULTATION_SUCCESS: null,
    GET_SELECTED_CONSULTATION_FAILURE: null,
});

export const {
    selectSingleConsultationRequest,
} = createActions(
    constants.SELECT_SINGLE_CONSULTATION_REQUEST,
);

export const {
    getConsultationsRequest,
    getConsultationsSuccess,
    getConsultationsFailure,
} = createActions(
    constants.GET_CONSULTATIONS_REQUEST,
    constants.GET_CONSULTATIONS_SUCCESS,
    constants.GET_CONSULTATIONS_FAILURE,

);

export const {
    saveConsultationRequest,
    saveConsultationSuccess,
    saveConsultationFailure,
} = createActions(
    constants.SAVE_CONSULTATION_REQUEST,
    constants.SAVE_CONSULTATION_SUCCESS,
    constants.SAVE_CONSULTATION_FAILURE,
);

export const {
    getSelectedConsultationRequest,
    getSelectedConsultationSuccess,
    getSelectedConsultationFailure,
} = createActions(
    constants.GET_SELECTED_CONSULTATION_REQUEST,
    constants.GET_SELECTED_CONSULTATION_SUCCESS,
    constants.GET_SELECTED_CONSULTATION_FAILURE,
);
