import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './oneOffPurchaseButton.scss';
import history from '../../core/utils/history';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation, getHoroscopeDataRange } from '../../core/utils/helpers';
import { saveOneOffPaymentRequest } from '../../core/payment/paymentActions';

const OneOffPurchaseButton = ({ horoscopeContentFrequency, btnClass }) => {

    const { language } = useLanguage();
    const dispatch = useDispatch();
    const horoscope = useSelector(state => state.horoscope);

    useEffect(() => {

    }, [horoscopeContentFrequency]);

    const handleOneOffSubmit = () => {
        dispatch(saveOneOffPaymentRequest(horoscope?.singleHoroscope));
        history.push({ pathname: '/one-off-confirmation', state: { data: horoscope?.singleHoroscope } });
    };

    return (
        <div style={{ marginTop: 10, width: btnClass === 'invert' ? '100%' : '100%', alignItems: 'center' }}>
            <button className="submit-btn-yellow" style={{ marginLeft: btnClass === 'invert' ? '0' : '0', width: btnClass === 'invert' ? '75%' : '50%' }} type="button" href="#" onClick={() => { handleOneOffSubmit(); }}>
                {getTranslation(language, 'buy', 'Buy')}  {getHoroscopeDataRange(horoscope?.singleHoroscope) } {getTranslation(language, 'premium_horoscope', 'Premium Horoscope')}
            </button>
        </div>
    );

};

OneOffPurchaseButton.propTypes = {
    horoscopeContentFrequency: PropTypes.any,
    selectDefaultUserHoroscope: PropTypes.bool,
    btnClass: PropTypes.string,

};

OneOffPurchaseButton.defaultProps = {
    horoscopeContentFrequency: 1,
    selectDefaultUserHoroscope: false,
    btnClass: 'normal',

};

export default OneOffPurchaseButton;
